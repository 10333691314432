import axios from 'axios';
import SecureStorage from '../services/secureStorage'; // Updated import for the singleton SecureStorage
import { ClimateScenario, EntityLevel, SearchType } from '../utils/options';
import { filterStore } from '../stores/filterStore';
import { GetLocalStorage } from '../utils/cryptography';
import { TransformRegionOptionsParam, TransformSectorOptionsParam } from '../utils/general';

const API_VERSION = 'v2';

// ==============================|| ORGANIZATION ||============================== //

// Type definitions for the function parameters
interface EntityListParams {
    asset_owner_id?: number | null;
    asset_id?: number | null;
    financial_year?: string | null;
    include_trajectory?: boolean;
    entityType?: string;
}

interface SearchEntityListParams {
    search_term?: string;
    financial_year?: string | null;
    entityType?: string;
}

interface OrganizationSummaryParams {
    financial_year?: string;
    type?: string | null;
}

interface PortfolioClimateScoreParams {
    financial_year?: string | null;
}

interface EmissionDecilesParams {
    financial_year?: string | null;
}

interface NetEmissionDecilesParams extends EmissionDecilesParams {
    lower_band?: number;
    higher_band?: number;
}

interface EmissionBarChartDataParams {
    entity_id?: number | null;
    financial_year?: string | null;
}

interface PortfolioPCAFScoreParams {
    financial_year?: string;
}

interface KeyInformationParams {
    financial_year?: string;
}

interface OrganizationStatisticsParams {
    type?: string;
    financial_year?: string;
}

interface AssetGeoJsonParams {
    entity_id: number;
    financial_year: string;
}

interface CustomerStatisticsParams {
    entity_id?: number | null;
    financial_year?: string | null;
    type?: string | null;
    entity?: string;
}

interface CustomerLandUseAndRemovalParams {
    entity_id?: number | null;
    financial_year?: string | null;
}

interface CustomerBenchmarkingParams {
    financial_year?: string | null;
    benchmarkingType: string;
}

interface CustomerSummaryParams {
    entity_id?: number | null;
    financial_year?: string | null;
    entity_type?: string;
}

// General function to handle the Axios requests and return the data
async function postRequest(url: string, data: any, token: string) {
    try {
        const response = await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (err) {
        throw err;
    }
}

// Example: Get Entity List
export async function getEntityList(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    asset_owner_id: string,
    asset_id: string,
    emission_system_id: string,
    financial_year: string
}, entityType: "organization" | "asset_owner" | "asset") {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/v1/portfolio/${entityType}/list`;
    const data = {
        ...params,
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Search Entity List
export async function searchEntityList(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    search_term: string,
    financial_year: string
}, entityType: "asset_owner" | "asset") {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/v1/portfolio/${entityType}/search`;
    const data = {
        ...params,
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Organization Summary
export async function getOrganizationSummary(params: OrganizationSummaryParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/summary`;
    const data = {
        financial_year: params.financial_year || '2023',
        type: params.type,
    };

    return await postRequest(url, data, token);
}

// Example: Get Portfolio Climate Score Summary
export async function getPortfolioClimateScoreSummary(params: PortfolioClimateScoreParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/climate_score/average`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Emission Deciles
export async function getPortfolioClimateScoreDeciles(params: EmissionDecilesParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/climate_score/deciles`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Portfolio Net Emission Deciles
export async function getPortfolioNetEmissionDeciles(params: NetEmissionDecilesParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/net_emission/deciles`;
    const data = {
        financial_year: params.financial_year || '2023',
        lower_band: params.lower_band || 5000,
        higher_band: params.higher_band || 30000,
    };

    return await postRequest(url, data, token);
}

// Example: Get Emission Intensity Bar Chart Data
export async function getEmissionIntensityBarChartData(params: EmissionBarChartDataParams, entity_type = 'organization') {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/emission_intensities`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Portfolio PCAF Score
export async function getPortfolioPCAFScore(params: PortfolioPCAFScoreParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/data_quality_score`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Portfolio Key Information
export async function getPortfolioKeyInformation(params: KeyInformationParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/key_information`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Organization Statistics Graph Data
export async function getOrganizationStatisticsGraphData(params: OrganizationStatisticsParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/statistics`;
    const data = {
        type: params.type || 'sector_emissions',
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// option apis
export async function getRegionOptions(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climate_scenario: string,
    report_period: string,
    region_type: string
}) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/options/regions`;
    const data = {
        ...params,
        report_period: params.report_period || '2023',
    };

    return await postRequest(url, data, token);
}

export async function getSectorOptions(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    report_period: string
}) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/options/sectors`;
    const data = {
        ...params,
        report_period: params.report_period || '2023',
    };

    return await postRequest(url, data, token);
}

export async function getYearOptions(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    report_period: string,
    include_report_period: boolean
}) {
    const secureStorage = SecureStorage.getInstance();
    let token = secureStorage.getJwtToken();
    const localToken = GetLocalStorage("accessToken")

    if (!token && localToken) {
        token = localToken
    }

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/options/years`;
    const data = {
        ...params,
        report_period: params.report_period || '2023',
    };

    return await postRequest(url, data, token);
}

export async function getLatestAvailableFY(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    sector: any[] | "",
    regions: any[] | ""
}) {
    const secureStorage = SecureStorage.getInstance();
    let token = secureStorage.getJwtToken();
    const localToken = GetLocalStorage("accessToken")

    if (!token && localToken) {
        token = localToken
    }

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/options/latest_available_year`;
    const data = {
        ...params,
    };

    return await postRequest(url, data, token);
}

export async function getDashboardData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
}, entity_type: "organization" | "asset_owner" | "asset", first_filter: "cards" | "lu" | "fe" | "climate_risk_score_deciles") {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }
    // /v2/portfolio/organization/summary/stat/climate_risk_score_deciles'
    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/stat/${first_filter}`;
    const data = {
        ...params,
        regions: filterStore.searchType === SearchType.REGION ? TransformRegionOptionsParam(filterStore.searchedRegions) : "",
        sectors: filterStore.searchType === SearchType.SECTOR ? TransformSectorOptionsParam(filterStore.searchedSectors) : "",
        reportPeriod: params.reportPeriod || '2023',
    };

    return await postRequest(url, data, token);
}


export async function getOrganizationAEData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    metricTitle?: string
},
    entity_type: "organization" | "asset_owner" | "asset",
    second_filter: "emissions" | "emission_intensity" | "physical",
    third_filter: "scope" | "emission_summary" | "ghg" | "sector" | "region" | "emissions_intensity_per_hectare_by_farm" | "non_biological_emissions_intensity_by_scope" | "emission_per_ha_by_farm" | "emission_per_production_by_farm",
    fourth_filter?: string
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/ae/${second_filter}/${third_filter}${fourth_filter ? `/${fourth_filter}` : ""}`;
    const data = {
        ...params,
        regions: filterStore.searchType === SearchType.REGION ? TransformRegionOptionsParam(filterStore.searchedRegions) : "",
        sectors: filterStore.searchType === SearchType.SECTOR ? TransformSectorOptionsParam(filterStore.searchedSectors) : "",
        reportPeriod: params.reportPeriod || '2023',
    };

    return await postRequest(url, data, token);
}

export async function getOrganizationEmissionFarmListData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    rangeStart: number,
    rangeEnd: number
},
    entity_type: "organization" | "asset_owner" | "asset",
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/ae/physical/emission_per_ha_by_farm/search`;
    const data = {
        ...params,
        regions: filterStore.searchType === SearchType.REGION ? TransformRegionOptionsParam(filterStore.searchedRegions) : "",
        sectors: filterStore.searchType === SearchType.SECTOR ? TransformSectorOptionsParam(filterStore.searchedSectors) : "",
        reportPeriod: params.reportPeriod || '2023',
    };

    return await postRequest(url, data, token);
}

export async function getOrganizationFEData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
},
    entity_type: "organization" | "asset_owner" | "asset",
    second_filter: "emissions" | "intensity",
    third_filter: "scope" | "sector" | "region" | "eei" | "pei" | "waci"
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/fe/${second_filter}/${third_filter}`;
    const data = {
        ...params,
        regions: filterStore.searchType === SearchType.REGION ? TransformRegionOptionsParam(filterStore.searchedRegions) : "",
        sectors: filterStore.searchType === SearchType.SECTOR ? TransformSectorOptionsParam(filterStore.searchedSectors) : "",
        reportPeriod: params.reportPeriod || '2023',
    };

    return await postRequest(url, data, token);
}


export async function getOrganizationPRData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    sectors?: string[] | "all",
    regions?: string[] | "all"
},
    entity_type: "organization" | "asset_owner" | "asset",
    second_filter: "acute_risks" | "chronic_risks",
    third_filter: "drought" | "temperature" | "flood" | "rainfall"
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/pr/${second_filter}/${third_filter}`;
    const data = {
        ...params,
        regions: filterStore.searchType === SearchType.REGION ? TransformRegionOptionsParam(filterStore.searchedRegions) : "",
        sectors: filterStore.searchType === SearchType.SECTOR ? TransformSectorOptionsParam(filterStore.searchedSectors) : "",
        reportPeriod: params.reportPeriod || '2023',
    };

    return await postRequest(url, data, token);
}


export async function getOrganizationLandUseData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    sectors?: string[] | "all",
    regions?: string[] | "all"
},
    entity_type: "organization" | "asset_owner" | "asset",
    second_filter: "landuse" | "removals",
    third_filter: "summary" | "map" | "land_use_type" | "carbon_stock_type"
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/lu/${second_filter}/${third_filter}`;
    const data = {
        ...params,
        reportPeriod: params.reportPeriod || '2023',
    };

    return await postRequest(url, data, token);
}


// ==============================|| CUSTOMERS ||============================== //

// Example: Get Asset GeoJson
export async function getAssetGeoJson(params: AssetGeoJsonParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/asset/geojson`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Customer Statistics
export async function getCustomerStatistics(params: CustomerStatisticsParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${params.entity || 'asset_owner'}/statistics`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
        type: params.type,
    };

    return await postRequest(url, data, token);
}

// Example: Get Customer Land Use and Removal
export async function getCustomerLandUseAndRemoval(params: CustomerLandUseAndRemovalParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/v0/portfolio/asset/landuse_and_removals`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Customer Benchmarking
export async function getCustomerBenchmarking(params: CustomerBenchmarkingParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/benchmarkingType`;
    const data = {
        benchmarkingType: params.benchmarkingType,
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}

// Example: Get Customer Summary
export async function getCustomerSummary(params: CustomerSummaryParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${params.entity_type || 'asset_owner'}/summary`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return await postRequest(url, data, token);
}


export async function exportFile(url: string, params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    sectors?: string[] | "all",
    regions?: string[] | "all"
}, fileName: string) {
    const response = await axios.post(url, params, {
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${SecureStorage.getInstance().getJwtToken()}`,
        },
    });

    const objectUIrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = objectUIrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}