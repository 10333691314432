import React, { useContext, useEffect, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'

import removalsMap from "../../../images/demo_images/land_use_landusetype.png"
import { Box, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import VmModal from '../../VmModal'
import { FilterLevelThree } from '../../summary/filter-section'
import { APIErrorHandler } from '../../../utils/general'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
import { useSnackbar } from 'notistack'

const SummaryRemovalsMapSection = observer(({ levelThreeId }: { levelThreeId: FilterLevelThree }) => {
  const { reportPeriod, setAllowedCompareToYears, } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)

  const [loading, setLoading] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>('')
  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false)
  const [climateChangeImageUrl, setClimateChangeImageUrl] = useState<string>('')
  const [error, setError] = useState<string>('')

  const refreshMap = (fy: number = reportPeriod) => {
    const levelTwoParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_MAP ? "removals" : "landuse"

    if (showClimateChangeModal) {
      setClimateChangeModalLoading(true)
    } else {
      setLoading(true)
    }

    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, levelTwoParamStr, "map")
      .then((data) => {
        if (showClimateChangeModal) {
          setClimateChangeImageUrl(data.data.url)
        } else {
          setImageUrl(data.data.url)
        }
      })    
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => {
        if (showClimateChangeModal) {
          setClimateChangeModalLoading(false)
        } else {
          setLoading(false)
        }
      })
  }
  
  const handleExportData = () => {
    const levelTwoParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_MAP ? "removals" : "landuse"

    exportFile(
      GenerateExportUrl("lu", levelTwoParamStr, "map"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Land Use and Removals - Summary.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  useEffect(() => {
    refreshMap()
  }, [showClimateChangeModal, selectedClimateScenario])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
        hideCompareTo={true}
        onClickExport={handleExportData}
        changeReportPeriodCallBack={refreshMap}
        showStressTestToggle={false}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
        showBenchmarkToggle={false}
        showTargetToggle={false}
        showRow1={false}
      />

      {
        loading
          ? <BannerText text={"Loading..."} />
          : <Box className="flex flex-col justify-center items-center">
            {error && <p className='text-red-500'>Failed to fetch map: {error}</p>}
            <img src={imageUrl} alt="lu-map" className='w-full max-h-[550px] object-contain' />
          </Box>
      }

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex flex-col gap-4">
          <Box className="flex items-center justify-between">
            <Typography variant="h6">Stress Test</Typography>
            <IconButton onClick={() => setShowClimateChangeModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <ClimateChangeToggleBar selectedClimateScenario={selectedClimateScenario} setSelectedClimateScenario={setSelectedClimateScenario} />
          {
            climateChangeModalLoading
              ? <BannerText text={"Loading..."} />
              : <Box className="flex flex-col justify-center items-center">
                {error && <p className='text-red-500'>Failed to fetch map: {error}</p>}
                <img src={climateChangeImageUrl} alt="lu-map" className='w-full max-h-[550px] object-contain' />
              </Box>
          }
        </Box>
      </VmModal>
    </>
  )
})

export default SummaryRemovalsMapSection
