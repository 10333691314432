import { Box, Fade, Modal } from "@mui/material";
import { observer } from "mobx-react";

const VmModal = ({ width, open, onClose, height = '90vh', children }: { width?: string, open: boolean, onClose: () => void, height?: string, children: any }) => {
  const MODAL_STYLE = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ? width : '70%',
    bgcolor: 'white',
    borderRadius: 3,
    boxShadow: 24,
  };

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={MODAL_STYLE}>
            <Box className="modalScrollBar overflow-auto px-8 py-6 flex flex-col gap-4" sx={{ height, maxHeight: '95vh' }}>{children}</Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default observer(VmModal);
