import { Box } from '@mui/material'
import React from 'react'

const BannerText = ({ text, subText }: { text: string, subText?: string }) => {
  return (
    <Box className="flex flex-col items-center justify-center w-full h-[400px] text-center text-gray-500 gap-2">
      <p>{text}</p>
      <p className='text-sm opacity-70'>{subText}</p>
    </Box>
  )
}

export default BannerText
