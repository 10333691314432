import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { IsEmptyStr, IsZero, AUDDateFormat, AUDTimeFormat, AUDateTimeFormat, Priority } from "../utils/general";
import { Box, Button, Chip, IconButton, SvgIconTypeMap, TextField, Tooltip } from "@mui/material";
import React from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import ImageIcon from '@mui/icons-material/Image';
// import { GetStatusDescription, OutgoingStage, Priority, RequestStageStatus, Responsibility } from "../utilities/maintenance";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { OpenInNew } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
// import { TicketStage } from "../utilities/enquiry-ticket";

export const ITEM_PERFECT_INLINED = { display: 'flex', alignItems: 'center', flexWrap: 'wrap' };
export const REQUIRED_ACTION = "bg-orange-100 text-orange-500";
export const SUCCESS = "bg-green-100 text-green-500";
export const THEME_GREEN = '#4a9149';

export interface VmTableSearch { searchFields: string[], title: string, placeHolder?: string; }
export interface VmTableButton {
  label: string,
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | any,
  onClick: (req: any, event: any) => any,
};
export interface VmTBody {
  type: VmTBodyType,
  isEnable?: boolean,
  fields: string[] | VmTableButton[] | {
    compareBy: any,
    compareTo: any,
    operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&",

    true: string[] | VmTableButton[] | {
      compareBy: any,
      compareTo: any,
      operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&",
      true: string[],
      false: string[],
      enableIndicator: boolean,
    };
    false: string[] | VmTableButton[] | {
      compareBy: any,
      compareTo: any,
      operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&",
      true: string[],
      false: string[],
      enableIndicator: boolean,
    };
  } | {
    fields: string[],
    onClick: (req: any, event: any) => any,
  };
};
export enum VmTBodyType {
  NO,
  TEXT,
  NUMBER,
  COMPLEX,
  DESCRIPTION,
  CURRENCY,
  DATE,
  DATE_TIME,
  TIME,
  BUTTON,
  TEXT_LINK,
  TEXT_BUTTON,
  STATUS,
  CONDITION,
  URGENT,
  PRIORITY,
  IMAGES,
  CHIP_LIST,
  MULTILINE,
  CASE_DATE_TIME,
};

export const EntityListTable = observer(({
  loading,
  thead,
  source,
  tbody,
  enableLoadMore,
  actualCount,
  onLoadMore,
  onClickRow,
  sortByDefault = true,
  highlightRow,
  hardRefresh,
  headerColour,
  defaultSortBy = "Last Updated Time",
  defaultSortByAscend = false,
  fixLastColumn = false,
  onSortCallback,
}:
  {
    loading?: boolean,
    thead: string[],
    source: any[],
    tbody: VmTBody[],
    actualCount?: number, // actual number of records return from the API
    enableLoadMore: boolean,
    onLoadMore?: () => any,
    onClickRow?: (req: any) => any,
    sortByDefault?: boolean;
    highlightRow?: { field: string, value: any; },
    hardRefresh?: boolean;
    defaultSortBy?: string;
    defaultSortByAscend?: boolean;
    headerColour?: string;
    onSortCallback?: (thIndex: number, ascending: boolean) => any;
    fixLastColumn?: boolean;
  }) => {
  const tbodyFirstColStyle = "px-2 py-3 bg-[#19154d] text-white";
  const tbodyColStyle = "px-2 py-3";
  const [sortBy, setSortBy] = useState<number>(-1);
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [searchKey, setSearchKey] = useState<string>("");
  const [filteredList, setFilteredList] = useState<any[]>(source);
  const [refresh, setRefresh] = useState<any>([]);
  const fontSize = 14.5;

  useEffect(() => {
    if (thead[0]) {
      setSearchKey("");
      let idx = thead.includes(defaultSortBy) ? thead.indexOf(defaultSortBy) : thead.indexOf("Issue Time");
      onSortList(idx > - 1 ? idx : 0, defaultSortByAscend, "", sortByDefault);
    }
  }, [source]);

  // Process complex data
  const processComplexField = (fields: string[], tableRow: any) => {
    let strResult = "";
    fields.map((field: string) => {
      if (field.includes(".")) {
        let rowData = tableRow;
        for (var f of field.split(".")) {
          if (rowData[f]) rowData = rowData[f];
          else {
            rowData = "-";
            break;
          }
        }
        strResult = !IsEmptyStr(strResult) ? `${strResult} ${rowData}` : rowData;
      } else strResult = !IsEmptyStr(strResult) ? `${strResult} ${tableRow[field]}` : `${tableRow[field]}`;
    });
    return IsEmptyStr(strResult) ? "-" : strResult;
  };

  // translate tBody string inputs from outside of the component to readable table data
  const translateInputsToTableBody = (fieldData: VmTBody, index: number) => {
    let tableRow = filteredList[index];
    let fields: string[] | any = fieldData.fields;
    switch (fieldData.type) {
      case VmTBodyType.DESCRIPTION:
        if (tableRow[fields[0]]) return tableRow[fields[0]].substring(0, 40) + `${tableRow[fields[0]].length > 40 ? '...' : ''}`;
        return "-";
      case VmTBodyType.CURRENCY:
        return `$${tableRow[fields[0]] ? (+tableRow[fields[0]]).toFixed(2).toLocaleString() : 0}`;
      case VmTBodyType.DATE:
        if (tableRow[fields[0]]) return moment(tableRow[fields[0]]).format(AUDDateFormat);
        return "-";
      case VmTBodyType.DATE_TIME:
        if (tableRow[fields[0]]) return moment(tableRow[fields[0]]).format(AUDateTimeFormat);
        return "-";
      case VmTBodyType.CASE_DATE_TIME:
        if (tableRow[fields[0]]) return moment(tableRow[fields[0]]).format(AUDateTimeFormat);
        return "-";
      case VmTBodyType.TIME:
        if (tableRow[fields[0]]) return moment(tableRow[fields[0]]).format(AUDTimeFormat);
        return "-";
      case VmTBodyType.COMPLEX: return processComplexField(fields, tableRow);
      case VmTBodyType.STATUS: return processComplexField(fields, tableRow);
      case VmTBodyType.TEXT_BUTTON:
        // @ts-ignore
        if (tableRow[fieldData.fields[0].field]) return tableRow[fieldData.fields[0].field];
        return;
      default:
        if (tableRow[fields[0]]) return tableRow[fields[0]].toLocaleString();
        return "-";
    }
  };

  // Sort list based on data type
  const onSortList = (thIndex: number, ascending: boolean, key: string, reverseSort: boolean, isManualSort: boolean = false) => {
    if (isManualSort) onSortCallback && onSortCallback(thIndex, ascending);
    if (tbody.length === 0) return;
    // if (tbody[thIndex].type === VmTBodyType.BUTTON) return;
    let targetCol: VmTBody | any = tbody[thIndex];
    let tempSourceList = source;
    if (!tempSourceList || tempSourceList.length === 0) return;
    if (!ascending) {
      if (targetCol.type === VmTBodyType.TEXT || targetCol.type === VmTBodyType.DESCRIPTION) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => `${a[targetCol.fields[0]]}`.localeCompare(`${b[targetCol.fields[0]]}`, 'zh'));
      } else if (targetCol.type === VmTBodyType.PRIORITY) {
        const rewording = (prio: Priority) => {
          return prio == Priority.HIGH ? "D" : prio == Priority.MEDIUM ? "C" : prio == Priority.LOW ? "B" : "A";
        };
        tempSourceList = tempSourceList.sort((a: any, b: any) => `${rewording(a[targetCol.fields[0]])}`.localeCompare(`${rewording(b[targetCol.fields[0]])}`, 'zh'));
      } else if (targetCol.type === VmTBodyType.TEXT_LINK) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => processComplexField(targetCol.fields.fields, a)
          .localeCompare(processComplexField(targetCol.fields.fields, b), 'zh'));
      } else if (targetCol.type === VmTBodyType.DATE || targetCol.type === VmTBodyType.TIME) {
        console.log(tempSourceList.sort((a: any, b: any) => moment(a[targetCol.fields[0]]).isBefore(moment(b[targetCol.fields[0]])) ? - 1 : 1));
        // @ts-ignore
        tempSourceList = tempSourceList.sort((a: any, b: any) => moment(a[targetCol.fields[0]]).isBefore(moment(b[targetCol.fields[0]])) ? - 1 : 1);
      } else if (targetCol.type === VmTBodyType.CASE_DATE_TIME) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => moment(a.lastModifiedAt ? a.lastModifiedAt : a.createdAt).isBefore(moment(b.lastModifiedAt ? b.lastModifiedAt : b.createdAt)) ? - 1 : 1);
      } else if (targetCol.type == VmTBodyType.URGENT) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => `${a[targetCol.fields[0]] ? "Urgent" : "Non"}`.localeCompare(`${b[targetCol.fields[0]] ? "Urgent" : "Non"}`, 'zh'));
      } else {
        tempSourceList = tempSourceList.sort((a: any, b: any) => moment(a[targetCol.fields[0]]).isBefore(moment(b[targetCol.fields[0]])) ? - 1 : 1);
      }
    } else {
      if (targetCol.type === VmTBodyType.TEXT || targetCol.type === VmTBodyType.DESCRIPTION) {
        const returnData = (str: any) => {
          return str ? str : "";
        };
        tempSourceList = tempSourceList.sort((a: any, b: any) => returnData(b[targetCol.fields[0]]).localeCompare(returnData(a[targetCol.fields[0]]), 'zh'));
      } else if (targetCol.type === VmTBodyType.PRIORITY) {
        const rewording = (prio: Priority) => {
          return prio == Priority.HIGH ? "D" : prio == Priority.MEDIUM ? "C" : prio == Priority.LOW ? "B" : "A";
        };
        tempSourceList = tempSourceList.sort((a: any, b: any) => `${rewording(b[targetCol.fields[0]])}`.localeCompare(`${rewording(a[targetCol.fields[0]])}`, 'zh'));
      } else if (targetCol.type === VmTBodyType.TEXT_LINK) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => processComplexField(targetCol.fields.fields, b)
          .localeCompare(processComplexField(targetCol.fields.fields, a), 'zh'));
      } else if (targetCol.type === VmTBodyType.DATE || targetCol.type === VmTBodyType.DATE_TIME || targetCol.type === VmTBodyType.TIME) {
        // @ts-ignore
        // console.log(tempSourceList.sort((a: any, b: any) => moment(b[targetCol.fields[0]]).isBefore(moment(a[targetCol.fields[0]])) ? - 1 : 1));
        // tempSourceList = tempSourceList.sort((a: any, b: any) => new Date(b[targetCol.fields[0]]) - new Date(a[targetCol.fields[0]]));
        tempSourceList = tempSourceList.sort((a: any, b: any) => moment(b[targetCol.fields[0]]).isBefore(moment(a[targetCol.fields[0]])) ? - 1 : 1);
      } else if (targetCol.type === VmTBodyType.CASE_DATE_TIME) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => moment(b.lastModifiedAt ? b.lastModifiedAt : b.createdAt).isBefore(moment(a.lastModifiedAt ? a.lastModifiedAt : a.createdAt)) ? - 1 : 1);
      } else if (targetCol.type === VmTBodyType.STATUS) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => a.approvalStatusId - b.approvalStatusId);
        // a.caseStatusId ? (GetStatusDescription(a.caseStatusId).scheme.localeCompare(GetStatusDescription(b.caseStatusId).scheme))
        //   : GetStatusDescription(a.statusId).scheme.localeCompare(GetStatusDescription(b.statusId).scheme));
      } else if (targetCol.type == VmTBodyType.URGENT) {
        tempSourceList = tempSourceList.sort((a: any, b: any) => `${b[targetCol.fields[0]] ? "Urgent" : "Non"}`.localeCompare(`${a[targetCol.fields[0]] ? "Urgent" : "Non"}`, 'zh'));
      } else {
        tempSourceList = tempSourceList.sort((a: any, b: any) => moment(b[targetCol.fields[0]]).isBefore(moment(a[targetCol.fields[0]])) ? - 1 : 1);
      }
    }
    setFilteredList(tempSourceList);
    if (reverseSort == true) setIsAscending(!ascending);
    // console.log(tempSourceList);
    setSortBy(thIndex);
  };

  const getCompareCondition = (compareBy: any, operator: string, compareTo: any) => {

    switch (operator) {
      case "||": return compareBy || compareTo;
      case ">": return compareBy > compareTo;
      case ">=": return compareBy >= compareTo;
      case "<": return compareBy < compareTo;
      case "<=": return compareBy <= compareTo;
      case "==": return compareBy == compareTo;
      case "&&": return compareBy && compareTo;
    }
  };

  // Only if the row is clickable
  const onTriggeringButton = (item: any, project: any, event: any) => {
    item.onClick(project, event);
    event.stopPropagation();
  };

  // const onTriggeringImage = (event: any, id: number, source: ImageSource) => {
  //   // window.open(GetImage(id, source), '_blank');
  //   // event.stopPropagation();
  // };

  return (
    <>
      <table width={"100%"} className=" block overflow-x-auto">
        <thead className={`z-10 text-white whitespace-nowrap rounded-lg ${headerColour ? `bg_919191` : "bg-[#19154d]"}`} style={{ backgroundColor: headerColour ? `${headerColour} !important` : "" }}>
          {thead.map((th: string, i: number) => (
            <td
              key={`th_${i}`}
              className={`font-bold text-left text-sm 2xl:text-md px-3 py-2 cursor-pointer hover:bg-[#494672] transition-all duration-200
                ${i == 0 ? `rounded-l-md rounded-bl-none w-12 sticky left-0 bg-[#19154d] h-10`
                  : i == thead.length - 1 ? `rounded-r-md ${fixLastColumn && "sticky right-0 bg-[#19154d]"}`
                    : i == 1 ? `sticky left-12  bg-[#19154d] block h-10` : ""}`}
              style={{ fontSize, boxShadow: i == 1 ? "rgba(0, 0, 0, 0.1) 4px 0px 15px 0px" : (fixLastColumn && i == thead.length - 1) ? "rgba(0, 0, 0, 0.5) -3px -1px 14px -5px" : "" }}
              onClick={() => onSortList(i, isAscending, searchKey, true, true)}>
              <Box sx={ITEM_PERFECT_INLINED}>
                {th} {th !== "No." && (sortBy === i && <>{isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</>)}
              </Box>
            </td>
          ))}
        </thead>
        <tbody>{hardRefresh == true || (loading && IsZero(source.length)) || (!enableLoadMore && loading) ? <td colSpan={thead.length}><p>Loading...</p></td> : <>
          {filteredList.map((rowInfo: any, rowIndex: number) => (
            <>
              <tr className={`overflow-visible whitespace-nowrap ${highlightRow && rowInfo[highlightRow.field] == highlightRow.value ? "bg-red-100" : rowIndex % 2 == 0 ? "bg-white" : "bg-gray-100"} ${onClickRow ? 'hover:bg-gray-100 cursor-pointer' : ''} transition-all duration-300 rounded-lg`} key={`project_list_${rowIndex}`}
                onClick={onClickRow ? () => onClickRow(rowInfo) : () => { }}>
                {tbody.map((rowField: any, rowFieldIndex: number) => (
                  <td
                    key={`tbody_${rowIndex}_${rowFieldIndex}`}
                    className={`${rowFieldIndex == 0 ? `${tbodyColStyle} ${tbodyFirstColStyle} h-12 ${rowIndex === filteredList.length - 1 && "rounded-bl-md"} sticky left-0`
                      : rowFieldIndex == 1 ? `${tbodyColStyle} sticky  h-12 flex items-center justify-center left-12 bg-white`
                        : rowFieldIndex == thead.length - 1 && fixLastColumn ? `${tbodyColStyle} sticky right-0 flex items-center justify-center bg-white`
                          : tbodyColStyle}`}
                    style={{ boxShadow: rowFieldIndex == 1 ? "rgba(0, 0, 0, 0.1) 10px 0px 20px 0px" : (rowFieldIndex == thead.length - 1 && fixLastColumn) ? "rgba(0, 0, 0, 0.5) -3px -1px 14px -5px" : "" }}
                  >
                    {/* @ts-ignore */}
                    {rowField.type === VmTBodyType.BUTTON ? <>
                      {rowField.fields.map((f: VmTableButton) => (
                        <button
                          // disabled={f.isEnable === false}
                          className={"text-themeGreen"}
                          onClick={(e: any) => onTriggeringButton(f, rowInfo, e)}
                        // style={{ cursor: f.isEnable !== false ? "pointer" : "not-allowed", color: f.isEnable !== false ? "" : "#ccc" }}
                        >
                          {/* colorScheme={f.colorScheme ? f.colorScheme : ColorScheme.INITIAL}> */}
                          <Tooltip title={f.label}>
                            {f.icon}
                          </Tooltip>
                        </button>
                      ))}
                    </> : rowField.type === VmTBodyType.NO ? <Box sx={{ fontSize, textAlign: "center" }}>
                      {
                        rowField.fields[0] ? (+(+rowInfo[rowField.fields[0]]).toFixed(2)).toLocaleString() : rowIndex + 1
                      }
                    </Box>
                      : rowField.type === VmTBodyType.NUMBER ? <Box sx={{ fontSize }}>{(+(+rowInfo[rowField.fields[0]]).toFixed(2)).toLocaleString()}</Box>
                        : rowField.type === VmTBodyType.CHIP_LIST ? <Box sx={{ ...ITEM_PERFECT_INLINED, maxWidth: 300 }}>
                          {rowInfo[rowField.fields.fields[0]].length > 0 ? <>
                            {rowInfo[rowField.fields.fields[0]].filter((j: any, i: number) => i < 5).map((job: any, i: number) => (
                              <Chip label={job.name} size="small"
                                color={rowField.fields.onClick ? "warning" : "default"}
                                onClick={rowField.fields.onClick}
                                sx={{ mr: i < rowInfo[rowField.fields.fields[0]].length - 1 ? 0.5 : 0, my: 0.5 }} />
                            ))}
                            {rowInfo[rowField.fields.fields[0]].length > 5 && <Chip size="small" label={`and ${rowInfo[rowField.fields.fields[0]].length - 5} more...`} />}
                          </> : <p style={{ fontSize }}>Not Provided</p>}
                        </Box> : rowField.type === VmTBodyType.TEXT_LINK ?
                          <button className="hover:underline text-left hover:text-themeGreen" onClick={(e: any) => { e.stopPropagation(); rowField.fields.onClick(rowInfo, e); }}>
                            <Box sx={{ fontSize }}>
                              {rowField.fields.fields.length > 1 ? `${rowInfo[rowField.fields.fields[0]]} ${rowInfo[rowField.fields.fields[1]]}` : translateInputsToTableBody(rowField.fields, rowIndex)}
                              <OpenInNew sx={{ fontSize: 12, ml: 0.5, display: "inline-block" }} />
                            </Box>
                          </button>
                          : rowField.type === VmTBodyType.MULTILINE ? <Box>
                            {rowInfo[rowField.fields[0]].split("\n").map((str: string) => (
                              <p style={{ fontSize }}>{str}</p>
                            ))}
                          </Box> : rowField.type === VmTBodyType.TEXT_BUTTON ? <>
                            {/* @ts-ignore */}
                            {rowInfo[rowField.fields.fields[0]].map((parent: any, i: number) => (
                              /* @ts-ignore */
                              parent.label ? <Button className={`mb-1 text-xs ${i < rowInfo[rowField.fields.fields[0]].length - 1 ? 'mr-2' : ''}`} onClick={(e: any) => rowField.fields.onClick(parent, e)}
                                key={`text_button_${i}`}
                                variant="outlined">
                                <Tooltip title={`Go to ${parent.label}`}>
                                  {parent.label}
                                </Tooltip>
                              </Button> : <p>-</p>
                            ))}
                          </> : rowField.type === VmTBodyType.URGENT ? <>
                            <Box className={`${rowInfo[rowField.fields[0]] ? "bg-red-200 text-red-500" : "bg-green-100 text-green-500"} w-fit rounded-md font-bold py-1 px-2 text-xs 2xl:text-sm font-bold`}>
                              {/* {project[field.fields[0]] ? "Urgent" : "No Urgent"} */}
                              {rowInfo[rowField.fields[0]] ? <Tooltip title="Urgent"><SmsFailedOutlinedIcon /></Tooltip> : <Tooltip title="Normal"><TextsmsOutlinedIcon /></Tooltip>}
                            </Box>
                          </> : rowField.type === VmTBodyType.PRIORITY ? <>
                            <Box className={`${rowInfo[rowField.fields[0]] == Priority.HIGH ? "bg-red-200 text-red-500" : rowInfo[rowField.fields[0]] == Priority.LOW ? SUCCESS :
                              rowInfo[rowField.fields[0]] == Priority.MEDIUM ? REQUIRED_ACTION : "bg-gray-100 text-gray-500"} w-fit rounded-md font-bold py-1 px-2 text-xs 2xl:text-sm font-bold uppercase`}>
                              <Tooltip title={rowInfo[rowField.fields[0]] ? `${rowInfo[rowField.fields[0]]} Priority` : "N/A"}>{rowInfo[rowField.fields[0]] ? rowInfo[rowField.fields[0]] : "N/A"}</Tooltip>
                            </Box>
                          </> : rowField.type === VmTBodyType.STATUS ?
                            <Box sx={ITEM_PERFECT_INLINED}>
                              {/* <Tooltip title={translateInputsToTableBody(field, i)} arrow> */}
                              {/* <p>{GetStatusCodeIcon(project.approvalStatusId, project.id)}</p> */}

                              {/* <p className={`${project.caseStatusId ? (project.isArchived ? ERROR : (GetStatusDescription(project.caseStatusId).color)) : project.statusId ? ERROR : REQUIRED_ACTION} 
                              rounded-md text-sm py-1 px-2 text-black`}>
                                  {project.caseStatusId ? (project.isArchived ? <HighlightOffIcon /> : (GetStatusDescription(project.caseStatusId).scheme == "warning") ? <InfoOutlinedIcon />
                                    : GetStatusDescription(project.caseStatusId).scheme == "success" ? <CheckCircleOutlineOutlinedIcon /> : <InfoOutlinedIcon />)
                                    : project.statusId ? <HighlightOffIcon /> : <InfoOutlinedIcon />}</p> */}
                              {/* </Tooltip> */}
                            </Box>
                            // : field.type === VmTBodyType.IMAGES ?
                            //   <Box sx={ITEM_PERFECT_INLINED}>
                            //     {project[field.fields[0]].length > 0 ? project[field.fields[0]].filter((img: any, i: number) => i < 5).map((img: any, i: number) => (
                            //       <button title="View attachment"
                            //         className={i > 0 ? "ml-2" : ""}
                            //         key={`img_attachement_${i}`}
                            //         data-row-data={`image_${img.id}`}
                            //         onClick={(e) => onTriggeringImage(e, img.id, img.source)}>
                            //         <ImageIcon />

                            //       </button>
                            //     )) : <p style={{ fontSize }}>No Attachment</p>}
                            //   </Box>
                            : rowField.type == VmTBodyType.CONDITION ? <Box sx={{ fontSize }}>
                              {/* @ts-ignore */}
                              {getCompareCondition(Array.isArray(rowField.fields.compareBy) ? rowInfo[rowField.fields.compareBy] : rowField.fields.compareBy,
                                // @ts-ignore
                                rowField.fields.operator, Array.isArray(rowField.fields.compareTo) ? rowInfo[rowField.fields.compareTo] : rowField.fields.compareTo)
                                ? <>
                                  {/* @ts-ignore */}
                                  {typeof rowField.fields.true[0] === "string" ? processComplexField(rowField.fields.true, rowInfo)
                                    // @ts-ignore
                                    : rowField.fields.true.operator ? <>
                                      {getCompareCondition(Array.isArray(rowField.fields.true.compareBy) ? rowInfo[rowField.fields.true.compareBy] : rowField.fields.true.compareBy,
                                        // @ts-ignore
                                        rowField.fields.true.operator, Array.isArray(rowField.fields.true.compareTo) ? rowInfo[rowField.fields.true.compareTo] : rowField.fields.true.compareTo) ?
                                        <Box sx={ITEM_PERFECT_INLINED}>
                                          {rowField.fields.true.enableIndicator ? <span className={`h-2 w-2 rounded-full bg-green-500`} /> : <></>}
                                          <p className={`${rowField.fields.true.enableIndicator && 'ml-1 -mt-1'}`}>{processComplexField(rowField.fields.true.false, rowInfo)}</p>
                                        </Box>
                                        : <Box sx={ITEM_PERFECT_INLINED}>
                                          {rowField.fields.true.enableIndicator ? <span className={`h-2 w-2 rounded-full bg-red-500`} /> : <></>}
                                          <p className={`${rowField.fields.true.enableIndicator && 'ml-1 -mt-1'}`}>{processComplexField(rowField.fields.true.false, rowInfo)}</p>
                                        </Box>}
                                    </> : rowField.fields.true.map((trueItem: any) => (
                                      <Tooltip title={trueItem.label}>
                                        <IconButton
                                          // tooltip={trueItem.label}
                                          onClick={(e: any) => trueItem.onClick(rowInfo, e)}
                                        // colorScheme={trueItem.colorScheme ? trueItem.colorScheme : ColorScheme.INITIAL}
                                        >
                                          {trueItem.icon}
                                        </IconButton>
                                      </Tooltip>
                                    ))}
                                </> : <Box sx={{ fontSize }}>
                                  {/* @ts-ignore */}
                                  {typeof rowField.fields.false[0] === "string" ? processComplexField(rowField.fields.false, rowInfo)
                                    // @ts-ignore
                                    : rowField.fields.false.operator ? <>
                                      {getCompareCondition(Array.isArray(rowField.fields.false.compareBy) ? rowInfo[rowField.fields.false.compareBy] : rowField.fields.false.compareBy,
                                        // @ts-ignore
                                        rowField.fields.false.operator, Array.isArray(rowField.fields.false.compareTo) ? rowInfo[rowField.fields.false.compareTo] : rowField.fields.false.compareTo) ?
                                        <Box sx={ITEM_PERFECT_INLINED}>
                                          {rowField.fields.false.enableIndicator ? <span className={`h-2 w-2 rounded-full bg-green-500`} /> : <></>}
                                          <p className={`${rowField.fields.false.enableIndicator && 'ml-2 -mt-1'}`}>{processComplexField(rowField.fields.false.false, rowInfo)}</p>
                                        </Box>
                                        : <Box sx={ITEM_PERFECT_INLINED}>
                                          {rowField.fields.false.enableIndicator ? <span className={`h-2 w-2 rounded-full bg-red-500`} /> : <></>}
                                          <p className={`${rowField.fields.false.enableIndicator && 'ml-2 -mt-1'}`}>{processComplexField(rowField.fields.false.false, rowInfo)}</p>
                                        </Box>}
                                    </>
                                      // @ts-ignore
                                      : rowField.fields.false.map((trueItem: any) => (
                                        <Tooltip title={trueItem.label}>
                                          <IconButton
                                            // tooltip={trueItem.label}
                                            onClick={(e: any) => onTriggeringButton(trueItem, rowInfo, e)}
                                          // colorScheme={trueItem.colorScheme ? trueItem.colorScheme : ColorScheme.INITIAL}
                                          >
                                            {trueItem.icon}
                                          </IconButton>
                                        </Tooltip>
                                      ))}
                                </Box>}
                            </Box> : rowField.type == VmTBodyType.CASE_DATE_TIME ? <Box sx={{ fontSize }} className="max-w-propertySm 2xl:max-w-propertyLg">
                              {rowInfo.lastModifiedAt ? moment(rowInfo.lastModifiedAt).format(AUDateTimeFormat) : moment(rowInfo.createdAt).format(AUDateTimeFormat)}
                            </Box> : <Box className="max-w-propertySm 2xl:max-w-propertyLg" sx={{ fontSize }}>
                              {translateInputsToTableBody(rowField, rowIndex)}
                            </Box>}
                  </td>
                ))}
              </tr>
            </>
          ))}
        </>}</tbody>
      </table >

      {/* {!hardRefresh && <>
        {!IsZero(source.length) && enableLoadMore ? <>
          {loading ? <p>Loading more</p> : <>
            <p className="text-center mt-4 text-sm">Displayed<b className="text-red-500"> {filteredList.length} Record(s)</b>, with total {actualCount} records</p>
            <Box className="flex justify-center">
              <Button onClick={onLoadMore ? () => onLoadMore() : () => { }} className="transition-all mt-2 pl-2">
                <Box sx={ITEM_PERFECT_INLINED}>
                  <KeyboardArrowDownIcon />
                  <p className="ml-1 text-sm">Load More</p>
                </Box>
              </Button>
            </Box>
          </>}
        </> : !loading && <p className="text-center mt-4 text-sm text-gray-400">{filteredList.length == 0 ? "No" : actualCount} Result(s) Found</p>}
      </>} */}
    </>
  );
});

export const VmBasicTable = observer(({
  loading, thead, children, enableLoadMore, onLoadMore, currentCount, actualCount, sortedByCol, sortAscend, onClickToSort, headerColour }:
  {
    loading?: boolean,
    thead: string[],
    children: any,
    enableLoadMore: boolean,
    onLoadMore?: () => any,
    currentCount?: number,
    actualCount?: number,
    sortedByCol?: string,
    sortAscend?: boolean,
    onClickToSort?: (col: string) => any,
    headerColour?: string;
  }) => {

  return (
    <>
      <table width={"100%"}>
        <thead className={"sticky top-0 z-10 rounded-lg"} style={{ backgroundColor: headerColour ? `${headerColour}` : THEME_GREEN }}>
          {thead.map((th: string, i: number) => (
            <th key={`th_${i}`}
              className={`font-bold text-left text-sm 2xl:text-md px-4 py-2 text-white cursor-pointer hover:bg-transparent/50 transition-all duration-200 
            ${i == 0 ? 'rounded-l-lg' : i == thead.length - 1 && 'rounded-r-lg'}`}
              // @ts-ignore
              onClick={onClickToSort ? () => onClickToSort(th) : () => { }}>
              <Box sx={ITEM_PERFECT_INLINED}>
                {th} {sortedByCol && sortedByCol === th ? sortAscend === true ? <ArrowUpwardIcon /> : <ArrowDownwardIcon /> : ''}
              </Box>
            </th>
          ))}
        </thead>
        <tbody className="text-sm 2xl:text-md">{!enableLoadMore && loading ? <th colSpan={thead.length}><p>Loading</p></th> : <>{children}</>}
        </tbody>
      </table>

      {/* If load more is enable, that means this table require paginations */}
      {enableLoadMore && <>
        {loading ? <p>Loading More</p> : <>
          <p className="text-center mt-4 text-sm">Displayed<b className="text-red-500"> {currentCount} Record(s)</b>, with total {actualCount} records</p>
          <Box className="flex justify-center">
            {actualCount !== currentCount && <Button onClick={onLoadMore ? () => onLoadMore() : () => { }} className="transition-all mt-2 pl-2">
              <Box sx={ITEM_PERFECT_INLINED}>
                <KeyboardArrowDownIcon />
                <p className="ml-2 text-sm">Load More</p>
              </Box>
            </Button>}
          </Box>
        </>}
      </>}
    </>
  );
});