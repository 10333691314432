import React, { useContext, useEffect, useRef, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import { IconButton, Typography } from '@mui/material'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/material'
import VmModal from '../../VmModal'

import { APIErrorHandler } from '../../../utils/general'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
import { useSnackbar } from 'notistack'
import SimpleLineChart from '../../charts/simple-line-chart'
import { FilterLevelThree } from '../../summary/filter-section'
import { defaultDataKeyColours, RandomRGB } from '../../../utils/colour'


const SummaryLURemovalSummarySection = observer(({ levelThreeId }: { levelThreeId: FilterLevelThree }) => {
  const { reportPeriod, setAllowedCompareToYears, compareToYears, } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [climateChangeModalChartData, setClimateChangeModalChartData] = useState<any>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)

  const [dataKeys, setDataKeys] = useState<TableDataKeyType[]>([])

  const [unit, setUnit] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)
  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false)
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineChartData, setClimateChangeModalLineChartData] = useState<any>([])
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  const searchParamText = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_SUMMARY ? "removals" : "landuse"

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const refreshData = (fy: number = reportPeriod) => {
    setLoading(true)
    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, searchParamText, "summary")
      .then((data) => {
        let rawData = data.data;
        if ("data" in rawData) rawData = rawData.data

        setChartData(rawData)
        const fyOptions = rawData.map((data: any) => data.FY)
        setClimateChangeModalFyOptions(fyOptions)

        if (rawData.length > 0) {
          const keys = [
            ...Object.keys(rawData[0])
              .filter((key: string) => !["FY", "total"].includes(key))
              .map((key: string) => ({
                key: key,
                title: FormatKeyToLabel(key),
              })),
            {
              key: "total",
              title: "Total",
            }
          ]
          setDataKeys(keys)

          const unit = rawData[0]?.total?.unit || ""
          setUnit(unit)

          const firstYear = rawData[0].FY
          setAllowedCompareToYears(reportPeriod - (+firstYear))

          // CLIMATE CHNAGE MODAL
          let tempLineChartData: any[] = [];

          keys.forEach((key: any) => {
            tempLineChartData.push({
              name: key.title,
              type: 'line',
              data: rawData.map((data: any) => ({
                ...data[key.key],
                fy: data.FY
              })),
              symbol: "roundRect",
              symbolSize: 16,
            })
          })
          // console.log(tempLineChartData)
          setClimateChangeModalLineChartData(tempLineChartData)

        } else {
          setDataKeys([])
          setUnit("")
          setAllowedCompareToYears(0)
        }
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setLoading(false))
  }

  const handleClickClimateChange = () => {
    setClimateChangeModalLoading(true);
    setShowClimateChangeModal(true);

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all([ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) =>
      getOrganizationLandUseData({
        force_refresh: false,
        include_trajectory: true,
        climateScenario: climateScenario,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, filterStore.entityLevel, searchParamText, "summary")
        .then((data) => {
          let rawData = data.data;
          if ("data" in rawData) rawData = rawData.data
          rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

          if (climateChangeFYOptions.length === 0) {
            climateChangeFYOptions = rawData.map((data: any) => data.FY)
          }

          if (rawData.length === 0) return;

          const dataKeys = Object.keys(rawData[0])
            .filter((key: string) => !["FY"].includes(key));

          for (const key of dataKeys) {
            if (!dataKeyColours.current[key]) {
              dataKeyColours.current[key] = RandomRGB();
            }
          }

          dataKeys.forEach((key: any) => {
            climateChangeChartData.push({
              name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
              type: 'line',
              data: rawData.map((data: any) => ({
                ...data[key],
                fy: data.FY,
                climateScenario: climateScenario,
              })),
              climateScenario: climateScenario,
              itemStyle: {
                color: dataKeyColours.current[key] || RandomRGB()
              },
              symbol: "roundRect",
              symbolSize: 16,
              isTotal: key.toLowerCase() === "total",
            })
          })
        })
    ))
      .then(() => {
        climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))
        
        setClimateChangeModalLineChartData(climateChangeChartData)
        setClimateChangeModalFyOptions(climateChangeFYOptions)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setClimateChangeModalLoading(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("lu", searchParamText, "summary"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Land Use and Removals - Summary.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  useEffect(() => {
    refreshData()
  }, [reportPeriod])

  // console.log(chartData)

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleClickClimateChange}
        changeReportPeriodCallBack={refreshData}
        onClickExport={handleExportData}
        defaultBenchmark={false}
        defaultTarget={false}
        showStressTestToggle={levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_SUMMARY}
      />
      {
        loading
          ? <BannerText text={"Loading..."} />
          : chartData && <RegionDataTable
            tableData={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears && +data.FY <= reportPeriod)}
            dataKeys={dataKeys}
            unitCellContent={<>
              <p className="text-left opacity-80">Land Use Type</p>
              {unit && <p className="text-left text-sm opacity-40">{unit}</p>}
            </>}
          />
      }

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar
          selectedClimateScenario={selectedClimateScenario}
          setSelectedClimateScenario={setSelectedClimateScenario}
          setShowTotalOnly={setClimateChangeOnlyShowTotal}
          showTotalOnly={climateChangeOnlyShowTotal}
        />
        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
          yAxisTitle={unit}
          animation={false}
          loading={climateChangeModalLoading}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(FormatFYChartTitle)}
        // minYAxisValue={GetMinYAxisValue(climateChangeModalLineChartData.filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true))}
          series={climateChangeModalLineChartData
            .filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true)
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: value.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                    symbol: 'roundRect',
                    symbolSize: 20,
                  }
                })
            }))}
        />

      </VmModal>
    </>
  )
})

export default SummaryLURemovalSummarySection
