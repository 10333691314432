import React, { useState, createContext, Dispatch, SetStateAction, useEffect, useContext } from 'react';
import { GenerateFYOptions, GetCurrentFY } from '../utils/general';
import { ClimateScenario, EntityLevel, EntityObject, SearchType } from '../utils/options';
import { FilterStoreContext } from './filter-store-context';
import moment from 'moment';
import { GetLocalStorage, SetLocalStorage } from '../utils/cryptography';
import SecureStorage from '../services/secureStorage';

interface FilterContextType {
  showBenchmark: boolean
  setShowBenchmark: Dispatch<SetStateAction<boolean>> | any
  showTarget: boolean
  setShowTarget: Dispatch<SetStateAction<boolean>> | any
  compareToYears: number
  setCompareToYears: Dispatch<SetStateAction<number>> | any
  reportPeriod: number
  setReportPeriod: Dispatch<SetStateAction<number>> | any
  allowedCompareToYears: number
  setAllowedCompareToYears: Dispatch<SetStateAction<number>> | any

  allRegionOptions: { value: string, label: string }[]
  setAllRegionOptions: Dispatch<SetStateAction<{ value: string, label: string }[]>> | any
}

const FilterContext = createContext<FilterContextType>({
  showBenchmark: false,
  setShowBenchmark: () => { },
  showTarget: false,
  setShowTarget: () => { },
  compareToYears: 3,
  setCompareToYears: () => { },
  reportPeriod: 0,
  setReportPeriod: () => { },

  allowedCompareToYears: 3,
  setAllowedCompareToYears: () => { },

  allRegionOptions: [],
  setAllRegionOptions: () => { },
});

const FilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const latestAvailableFY = SecureStorage.getInstance().getItem('latestAvailableFY') || GetLocalStorage('latestAvailableFY') || 0

  const [showBenchmark, setShowBenchmark] = useState(false)
  const [showTarget, setShowTarget] = useState(false)
  const [compareToYears, setCompareToYears] = useState<number>(0)
  const [reportPeriod, setReportPeriod] = useState<number>(latestAvailableFY)
  const [allowedCompareToYears, setAllowedCompareToYears] = useState<number>(0)
  const [allRegionOptions, setAllRegionOptions] = useState<{ value: string, label: string }[]>([])

  return (
    <FilterContext.Provider value={{
      showBenchmark, setShowBenchmark,
      showTarget, setShowTarget,
      compareToYears, setCompareToYears,
      reportPeriod, setReportPeriod,
      allowedCompareToYears, setAllowedCompareToYears,
      allRegionOptions, setAllRegionOptions,
    }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterContextProvider };
