import React, { useContext, useState } from 'react';
import '../App.css';
import { Autocomplete, Avatar, Box, Chip, CircularProgress, Collapse, Divider, drawerClasses, FormControl, IconButton, InputBase, InputLabel, ListItemIcon, Menu, MenuItem, Select, Skeleton, Step, StepButton, StepLabel, Stepper, styled, SvgIconTypeMap, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import Logo from './Wollemai_Logomark.svg';
import SortIcon from '@mui/icons-material/Sort';
import GridViewIcon from '@mui/icons-material/GridView';
import PeopleIcon from '@mui/icons-material/People';
import GiteIcon from '@mui/icons-material/Gite';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { HtmlTooltip } from '../components/htmlToolTip';
import { FilterContext } from '../context/filter-bar-context';
import { EntityLevel, OrganizationTypes, SearchType } from '../utils/options';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import { FilterStoreContext } from '../context/filter-store-context';
import SecureStorage from '../services/secureStorage';
import { DeepCopy } from '../utils/general';

const CriteriaHeader = observer(({ breadCrumb, chipsOnly = false, entityLevelToggleCallback }: { breadCrumb: string, chipsOnly?: boolean, entityLevelToggleCallback?: (entityIds: any) => void }) => {
  const { } = useContext(FilterContext);
  const filterStore = useContext(FilterStoreContext);
  const secureStorage = SecureStorage.getInstance();
  const userInfo = secureStorage.getItem('userInfo');
  const userType: OrganizationTypes = userInfo?.organization_type;
  const [searchParams, setSearchParams] = useSearchParams()

  const getShortenedRegions = () => {
    return filterStore.searchedRegions.slice(0, 2).map(region => region.id).join(", ") + (filterStore.searchedRegions.length > 2 ? ` and ${filterStore.searchedRegions.length - 2} more` : "");
  }

  const getShortenedSector = () => {
    return filterStore.searchedSectors.slice(0, 2).map(sector => `${sector.id} ${sector.name ? sector.name : ""}`).join(", ") + (filterStore.searchedSectors.length > 2 ? ` and ${filterStore.searchedSectors.length - 2} more` : "");
  }

  const handleClear = (isAssetOwner: boolean) => {
    let newEntityIds: any = { ...filterStore.entityIds }
    if (isAssetOwner) {
      newEntityIds.assetOwnerId = null
      newEntityIds.assetId = null

      if (userType === OrganizationTypes.ASSET_OWNER || userType === OrganizationTypes.ASSET) {
        // do nothing, the user is an asset owner
      } else {
        filterStore.entityLevel = EntityLevel.ORGANIZATION
      }
    } else {
      newEntityIds.assetId = null

      if (userType === OrganizationTypes.ASSET) {
        // do nothing, the user is an asset owner
      } else {
        filterStore.entityLevel = EntityLevel.ASSET_OWNER
      }
    }

    filterStore.entityIds = newEntityIds

    const newSearchParams: any = {
      ...Object.fromEntries(searchParams.entries()),
      assetOwnerId: newEntityIds.assetOwnerId?.id || "",
      assetOwnerName: newEntityIds.assetOwnerId?.name || "",
    }

    if (!isAssetOwner) {
      newSearchParams.assetId = ""
      newSearchParams.assetName = ""
    }

    setSearchParams(newSearchParams)
    entityLevelToggleCallback && entityLevelToggleCallback(newEntityIds)
  }

  return (
    <Box className="flex items-center justify-between w-full text-gray-500 py-1 px-2 rounded-lg"
      style={{ background: chipsOnly ? "transparent" : 'linear-gradient(90deg, rgba(179,255,235,1) 0%, rgba(236,255,250,1) 49%, rgba(228,249,243,1) 100%)' }}>
      <p>{chipsOnly ? "" : breadCrumb}</p>
      <Box className="flex items-center gap-8">

        {/* Sector */}
        <HtmlTooltip title={<>
          {
            (filterStore.searchedSectors && filterStore.searchedSectors.length > 0)
              ? <>
                <Typography color="inherit">Selected {filterStore.searchedSectors.length}</Typography>
                {
                  filterStore.searchedSectors.map((sector, i) => (
                    <p key={`sector_selection_${i}`} className='text-sky-600'>{i + 1}. {sector.id} {sector.name ? sector.name : ""}</p>
                  ))
                }
              </>
              : <p className='text-sky-600'>All Sectors</p>
          }
        </>}>
          <button className='p-2'>
            Sector <b className='pl-2 text-black'>{(filterStore.searchType === SearchType.REGION || filterStore.searchedSectors.length === 0) ? "All" : getShortenedSector()}</b>
          </button>
        </HtmlTooltip>

        <Divider orientation='vertical' flexItem sx={{ my: 1, opacity: 0.8 }} />

        {/* Region */}
        <HtmlTooltip title={<>
          {
            (filterStore.searchedRegions && filterStore.searchedRegions.length > 0)
              ? <>
                <Typography color="inherit">Selected {filterStore.searchedRegions.length}</Typography>
                {
                  filterStore.searchedRegions.map((region, i) => (
                    <p key={`region_selection_${i}`} className='text-sky-600'>{i + 1}. {region.id}</p>
                  ))
                }
              </>
              : <p className='text-sky-600'>All Regions</p>
          }
        </>}>
          <button className='p-2'>
            Region <b className='pl-2 text-black'>{(filterStore.searchType === SearchType.SECTOR || filterStore.searchedRegions.length === 0) ? "All" : getShortenedRegions()}</b>
          </button>
        </HtmlTooltip>

        <Divider orientation='vertical' flexItem sx={{ my: 1, opacity: 0.8 }} />

        <HtmlTooltip title={<>
          {filterStore.entityIds.assetOwnerId ? <p className='text-sky-600'>{filterStore.entityIds.assetOwnerId.name}</p> : <p className='text-sky-600'>All Owners</p>}
        </>}>
          <button className='p-2 flex items-center'>
            Owner <b className='pl-2 text-black'>{filterStore.entityIds.assetOwnerId ? filterStore.entityIds.assetOwnerId.name || filterStore.entityIds.assetOwnerId.id : "All"}</b>
            {
              // Boolean(filterStore.entityIds.assetOwnerId) &&
              Boolean(filterStore.entityIds.assetOwnerId) && (userType === OrganizationTypes.ORGANIZATION) &&
              <Tooltip title="Clear Filter" sx={{ display: "inline-block", opacity: 0.3, "&:hover": { opacity: 1 } }}>
                <IconButton onClick={() => handleClear(true)} sx={{ p: 1 }}>
                  <Clear sx={{ fontSize: 16 }} />
                </IconButton>
              </Tooltip>
            }
          </button>
        </HtmlTooltip>

        <Divider orientation='vertical' flexItem sx={{ my: 1, opacity: 0.8 }} />

        <HtmlTooltip title={<>
          {filterStore.entityIds.assetId ? <p className='text-sky-600'>{filterStore.entityIds.assetId.name}</p> : <p className='text-sky-600'>All Assets</p>}
        </>}>
          <button className='p-2 flex items-center'>
            Asset <b className='pl-2 text-black'>{filterStore.entityIds.assetId ? filterStore.entityIds.assetId.name || filterStore.entityIds.assetId.id : "All"}</b>
            {
              // Boolean(filterStore.entityIds.assetId) &&
              Boolean(filterStore.entityIds.assetId) && (userType === OrganizationTypes.ORGANIZATION || userType === OrganizationTypes.ASSET_OWNER) &&
              <Tooltip title="Clear Filter" sx={{ display: "inline-block", opacity: 0.3, "&:hover": { opacity: 1 } }}>
                <IconButton onClick={() => handleClear(false)} sx={{ p: 1 }}>
                  <Clear sx={{ fontSize: 16 }} />
                </IconButton>
              </Tooltip>
            }
          </button>
        </HtmlTooltip>
      </Box>
    </Box >
  );
});

export default CriteriaHeader;
