import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import ScopeCompareBarChart from '../../charts/scope-compare-bar-chart'
import CompareFilters from '../../CompareFilters'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName } from '../../../utils/general'
import SimpleBarChart from '../../charts/simple-bar-chart'
import { FilterContext } from '../../../context/filter-bar-context'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { defaultDataKeyColours, lerpColor, RandomRGB } from '../../../utils/colour'
import { FilterLevelThree } from '../../summary/filter-section'
import VmModal from '../../VmModal'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { Close } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { FilterStoreContext } from '../../../context/filter-store-context'
import SimpleLineChart from '../../charts/simple-line-chart'


const SummeryAEIntensityIntensityHaProduction = observer(({ levelThreeId }: { levelThreeId: FilterLevelThree }) => {
  const { reportPeriod, compareToYears, setAllowedCompareToYears } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [climateChangeModalData, setClimateChangeModalData] = useState<any>([])

  const [farmNamesList, setFarmNamesList] = useState<string[]>([])
  const [climateChangeFarmNamesList, setClimateChangeFarmNamesList] = useState<string[]>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)
  const [unit, setUnit] = useState<string>("")

  const [benchmarkValue, setBenchmarkValue] = useState<number | null>(null)
  const [targetValue, setTargetValue] = useState<number | null>(null)
  const [averageValue, setAverageValue] = useState<number | null>(null)

  // const [climateChangeBenchmarkValue, setClimateChangeBenchmarkValue] = useState<number | null>(null)
  // const [climateChangeTargetValue, setClimateChangeTargetValue] = useState<number | null>(null)
  // const [climateChangeAverageValue, setClimateChangeAverageValue] = useState<number | null>(null)

  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineChartData, setClimateChangeModalLineChartData] = useState<any>([])

  const [colors, setColors] = useState<string[]>([])

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingStressTestModal, setLoadingStressTestModal] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  let entityLevelParam = filterStore.entityLevel === EntityLevel.ORGANIZATION ? EntityLevel.ORGANIZATION : EntityLevel.ASSET_OWNER
  let entityId = filterStore.entityLevel === EntityLevel.ORGANIZATION ? organizationId : filterStore.entityIds.assetOwnerId?.id

  const refreshData = (fy: number = reportPeriod) => {
    const setFarmNameFn = setFarmNamesList;
    const setBenchmarkValueFn = setBenchmarkValue;
    const setTargetValueFn = setTargetValue;
    const setAverageValueFn = setAverageValue;

    if (!entityId) return;

    setLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: entityId
    }, entityLevelParam,
      "emission_intensity",
      levelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA
        ? "emission_per_ha_by_farm"
        : "emission_per_production_by_farm"
    )
      .then((data) => {
        let farmIds: string[] = []
        let farmNames: string[] = []
        let tempFySeriesData: any = [];

        let rawData = data.data;
        if ("data" in rawData) rawData = rawData.data;
        rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY);

        if (rawData.length === 0) {
          setFarmNameFn([]);
          setUnit("");
          setAverageValue(null);
          setLoading(false);
          return;
        }

        const firstFY = rawData[0].FY
        setAllowedCompareToYears(fy - firstFY)

        const totalFYs = rawData.length;

        const colors: string[] = []
        for (let i = 0; i < totalFYs; i++) {
          colors.push(lerpColor("#1d62f4", "#9ab7f5", i / totalFYs))
        }
        setColors(colors)

        rawData.forEach((fyData: any, fyIndex: number) => {
          if (fyIndex === 0) {
            farmIds = fyData.data.map((farm: any) => farm.id)
            farmNames = fyData.data.map((farm: any) => farm.name)
            setFarmNameFn(farmNames)

            setUnit((fyData.data && fyData.data.length > 0)
              ? levelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA
                ? fyData.data[0].intensity_per_ha_unit
                : fyData.data[0].intensity_per_production_unit
              : ""
            )
          }

          if (fyData.benchmark && +fyData.FY === fy) setBenchmarkValueFn(fyData.benchmark)
          if (fyData.target && +fyData.FY === fy) setTargetValueFn(fyData.target)

          let averageValue = 0

          tempFySeriesData.push({
            name: +fyData.FY,
            type: 'bar',
            barGap: 0,
            barWidth: "20%",
            emphasis: {
              focus: 'series'
            },
            data: farmIds.map((farmId: string) => {
              const farm = fyData.data.find((farm: any) => farm.id === farmId)
              // console.log(farmId, fyData.data)

              const value = levelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA ? +farm.intensity_per_ha_value : +farm.intensity_per_production_value;
              if (+fyData.FY === +fy) {
                averageValue += value
              }

              if (farm) {
                return {
                  value: value,
                  itemStyle: (filterStore.entityLevel === EntityLevel.ASSET && farmId === filterStore.entityIds.assetId?.id)
                    ? {
                      color: "#ff8317"
                    }
                    : {}
                }
              }
              return 0
            }),
          })

          setAverageValueFn(averageValue / farmIds.length)
        })


        if (showClimateChangeModal) {
          setClimateChangeModalData(tempFySeriesData)
        } else {
          setChartData(tempFySeriesData)
        }
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setLoading(false))
  }

  const handleClickClimateChange = () => {
    setShowClimateChangeModal(true);
    setLoadingStressTestModal(true);

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all([ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
      return getOrganizationAEData({
        force_refresh: false,
        include_trajectory: true,
        climateScenario: climateScenario,
        reportPeriod: "" + reportPeriod,
        entityId: entityId
      }, entityLevelParam,
        "emission_intensity",
        levelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA
          ? "emission_per_ha_by_farm"
          : "emission_per_production_by_farm"
      ).then((data: any) => {

        let rawData = data.data;
        if ("data" in rawData) rawData = rawData.data
        rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

        if (climateChangeFYOptions.length === 0) {
          climateChangeFYOptions = rawData.map((data: any) => data.FY)
        }

        // const dataKeys = Object.keys(rawData[0].data[0])
        //   .filter((key: string) => !["FY"].includes(key));

        const farmNames = rawData[0].data.map((farm: any) => farm.name)

        for (const farmName of farmNames) {
          if (!dataKeyColours.current[farmName]) {
            dataKeyColours.current[farmName] = RandomRGB();
          }
        }

        farmNames.forEach((farmName: string) => {
          climateChangeChartData.push({
            name: `${farmName} - ${GetScenarioName(climateScenario)}`,
            type: 'line',
            data: rawData.map((fyData: any) => {
              const farmData = fyData.data.find((farm: any) => farm.name === farmName)
              const value = farmData ? (levelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA
                ? farmData.intensity_per_ha_value
                : farmData.intensity_per_production_value) : 0

              return {
                ...farmData,
                value: value,
                fy: fyData.FY,
                climateScenario: climateScenario,
              }
            }),
            climateScenario: climateScenario,
            itemStyle: {
              color: dataKeyColours.current[farmName] || RandomRGB()
            },
            isTotal: false,
          })
        })

        // console.log(rawData)

        // climateChangeChartData.push(data.data)
        // climateChangeFYOptions.push(data.FY)
      })
    }))
      .then(() => {
        climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))

        setClimateChangeModalLineChartData(climateChangeChartData)
        setClimateChangeModalFyOptions(climateChangeFYOptions)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setLoadingStressTestModal(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("ae", "emission_intensity",
        levelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA
          ? "emission_per_ha_by_farm"
          : "emission_per_production_by_farm"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Absolute Emission - Emission Intensity.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  useEffect(() => {
    refreshData()
  }, [levelThreeId])

  // console.log(climateChangeModalLineChartData)

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleClickClimateChange}
        changeReportPeriodCallBack={refreshData}
        onClickExport={handleExportData}
        defaultBenchmark={false}
        defaultTarget={false}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
      />
      <SimpleBarChart
        key={`${reportPeriod}-${compareToYears}`}
        series={chartData.filter((fySeries: any) => +fySeries.name >= +reportPeriod - +compareToYears && +fySeries.name <= +reportPeriod)}
        height="500px"
        yAxisTitle={unit}
        categories={farmNamesList}
        benchmarkValue={benchmarkValue}
        averageValue={averageValue}
        showZoom={true}
        loading={loading}
        targetValue={targetValue}
        colors={colors}
      />

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar
          selectedClimateScenario={selectedClimateScenario}
          setSelectedClimateScenario={setSelectedClimateScenario}
        />
        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}`}
          yAxisTitle={unit}
          animation={false}
          loading={loadingStressTestModal}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(FormatFYChartTitle)}
        // minYAxisValue={GetMinYAxisValue(climateChangeModalLineChartData)}
          series={climateChangeModalLineChartData
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: value.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                  }
                })
            }))}
        />


        {/* <SimpleBarChart
          key={`${reportPeriod}-${compareToYears}`}
          series={
            climateChangeModalData
              .filter((fySeries: any) => +fySeries.name >= +reportPeriod)
          }
          height="600px"
          yAxisTitle={unit}
          loading={loadingStressTestModal}
          categories={climateChangeFarmNamesList}
          benchmarkValue={climateChangeBenchmarkValue}
          averageValue={climateChangeAverageValue}
          showZoom={true}
          targetValue={climateChangeTargetValue}
          colors={colors}
        /> */}
      </VmModal>
    </>
  )
})


export default SummeryAEIntensityIntensityHaProduction
