import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { EntityLevel, SearchType } from "../utils/options";

class FilterStore {
  entityLevel: EntityLevel = EntityLevel.ORGANIZATION
  entityIds: any = {}
  searchedRegions: any[] = []
  searchedSectors: any[] = []
  searchType: string = SearchType.REGION

  regionOptions: any[] = []
  sectorOptions: any[] = []
  fyOptions: { value: number, label: string }[] = []
  latestAvailableFY: number = 0

  constructor() {
    makeAutoObservable(this);

    // makeObservable(this, {
    //   entityLevel: observable,
    //   entityIds: observable,

    //   setEntityLevel: action,
    //   setEntityIds: action,
    // });
  }
}

export const filterStore = new FilterStore();
