import React, { useContext, useEffect, useState } from 'react';
import { Box, Pagination, Skeleton, Typography } from '@mui/material';
import Layout from '../layouts/Layout';
import CriteriaHeader from '../layouts/criteria-header';
import { EntityListTable, VmTBody, VmTBodyType } from '../components/VmTables';
import { observer } from 'mobx-react-lite';
import FilterSection from '../components/summary/filter-section';
import CompareFilters from '../components/CompareFilters';
import { FilterContext } from '../context/filter-bar-context';
import { DownloadCSV, FormatKeyToLabel, GetCurrentFY } from '../utils/general';
import { getEntityList } from '../api/explorerService';
import { ClimateScenario, EntityLevel, OrganizationTypes } from '../utils/options';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { APIErrorHandler } from '../utils/general';
import { FilterStoreContext } from '../context/filter-store-context';
import BannerText from '../components/BannerText';
import { useSnackbar } from 'notistack';

const presetThead = [
  "No.",
  "Entity Name",
  "Entity Type",
  "Region",
  "Sector/System",
  // "Area",
  "Stress Test Type"
]

const PAGE_SIZE = 10

const OwnersPage = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const filterStore = useContext(FilterStoreContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { reportPeriod, setReportPeriod, setAllowedCompareToYears } = useContext(FilterContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [entityList, setEntityList] = useState<any[]>([])
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [displayedPage, setDisplayedPage] = useState<number>(1)

  useEffect(() => {
    setAllowedCompareToYears(0)
    searchAssetOwners()
  }, [])

  const handleSwitchToAssetOwner = (row: any) => {
    filterStore.entityIds = {
      assetOwnerId: {
        id: row.id,
        name: row.entity_name
      },
      assetId: null
    }

    let oldSearchParams = Object.fromEntries(searchParams.entries())

    let newSearchParams = {
      ...oldSearchParams,
      assetOwnerId: row.id || '',
      assetOwnerName: row.entity_name || ''
    }

    setSearchParams(newSearchParams)

    navigate({
      pathname: "/summary/dashboard",
      search: `?${createSearchParams(newSearchParams)}`
    })
  }

  const presetTBody = [
    { type: VmTBodyType.NO, fields: ["no"] },
    { type: VmTBodyType.TEXT_LINK, fields: { fields: ["entity_name"], onClick: (req: any, e: any) => handleSwitchToAssetOwner(req) } },
    { type: VmTBodyType.TEXT, fields: ["entity_type"] },
    { type: VmTBodyType.TEXT, fields: ["region"] },
    { type: VmTBodyType.TEXT, fields: ["sector"] },
    // { type: VmTBodyType.TEXT, fields: ["Ha"] },
    { type: VmTBodyType.TEXT, fields: ["stress_test_type"] },
  ]

  const [thead, setThead] = useState<string[]>([])
  const [tbody, setTbody] = useState<VmTBody[]>([])

  const searchAssetOwners = async (fy = reportPeriod) => {
    setLoading(true)
    getEntityList({
      financial_year: "" + fy,
      include_trajectory: false,
      force_refresh: false,
      climateScenario: ClimateScenario.CURRENT,
      asset_owner_id: "",
      asset_id: "",
      emission_system_id: "",
    }, 'organization')
      .then((response) => {
        if (response.data.items.length === 0 || response.data.totalCount === 0) return;

        let tempThead = [...presetThead]
        let tempTbody = [...presetTBody]

        setTotalAmount(response.data.totalCount)
        const originalKeys = Object.keys(response.data.items[0])

        const allItems = response.data.items.map((item: any, itemIndex: number) => {
          return {
            ...item,
            no: itemIndex + 1,
            entity_type: "Asset Owner",
            stress_test_type: "Current"
          }
        })

        setEntityList(allItems)

        originalKeys.forEach((key) => {
          if (["entity_name", "id", "region", "sector", "entity_type", "parent_asset_owner_id", "stress_test_type", "childCount"].includes(key)) return;
          if (key.endsWith("_v2") || key.endsWith("_unit") || key.endsWith("_decile")) return;

          // if (key.startsWith("net_emission_")) key = key.replace("net_emission_", "")
          // else if (key.startsWith("gross_emissions_")) key = key.replace("gross_emissions_", "")

          let keyLabel = FormatKeyToLabel(key);

          if (originalKeys.includes(`${key}_unit`)) {
            let unit = response.data.items[0][`${key}_unit`]
            tempThead.push(`${keyLabel}${unit ? ` (${unit})` : ""}`)
          } else {
            tempThead.push(keyLabel)
          }

          tempTbody.push({ type: VmTBodyType.NUMBER, fields: [key] })
        })

        tempThead.push("Asset Amounts")
        tempTbody.push({ type: VmTBodyType.NUMBER, fields: ["childCount"] })

        setThead(tempThead)
        setTbody(tempTbody)
        // console.log(dataKeys)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSearch = (search: { assetOwnerId: any, assetId: any }) => {
    // console.log(search)

    if (search.assetOwnerId) {
      filterStore.entityIds = {
        ...filterStore.entityIds,
        assetOwnerId: search.assetOwnerId
      }
      searchAssetOwners()
    }
  }

  if (filterStore.entityLevel === EntityLevel.ASSET || filterStore.entityLevel === EntityLevel.ASSET_OWNER) {
    // NO PERMISSION, LEAVE THIS PAGE
    navigate({
      pathname: "/summary/dashboard",
      search: `?${createSearchParams(searchParams)}`
    })
  }

  const exportData = () => {
    const exportThead = [...thead]
    const exportTbody = [...tbody]

    exportThead[0] = "ID"
    exportTbody[0] = { type: VmTBodyType.TEXT, fields: ["id"] }

    DownloadCSV(exportThead, exportTbody, entityList, "asset-owners-list.csv")
  }

  return (
    <Layout onTriggerAfterSearch={handleSearch}>
      <Box className='flex flex-col gap-2 w-full'>
        <CriteriaHeader breadCrumb={"Owners List"} />
        <CompareFilters changeReportPeriodCallBack={searchAssetOwners} hideCompareTo={true} showRow1={false} onClickExport={() => exportData()} />

        <Box className='w-full overflow-auto border rounded-lg p-2 bg-white'>
          <Box className='w-full overflow-auto'>
            {
              loading
                ? <BannerText text={"Loading..."} />
                : <EntityListTable
                  thead={thead}
                  source={entityList}
                  tbody={tbody}
                  enableLoadMore={false}
                  loading={loading}
                  fixLastColumn={true}
                />
            }

            <Box className='w-full flex justify-end items-center gap-2 p-2'>
              <Typography variant='body1'>Total {totalAmount}</Typography>
              <Pagination count={Math.ceil(totalAmount / PAGE_SIZE)} page={displayedPage} onChange={(event: any, page: number) => setDisplayedPage(page)} />
            </Box>
          </Box>
        </Box >
      </Box >
    </Layout>
  );
});

export default OwnersPage;
