import React, { useContext, useEffect, useState } from 'react';
import '../../App.css';
import { Autocomplete, Avatar, Box, Button, Chip, CircularProgress, Collapse, Divider, drawerClasses, FormControl, IconButton, InputBase, InputLabel, ListItemIcon, Menu, MenuItem, Select, Skeleton, Step, StepButton, StepLabel, Stepper, styled, SvgIconTypeMap, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import { FilterContext } from '../../context/filter-bar-context';
import { EntityLevel } from '../../utils/options';
import { FilterStoreContext } from '../../context/filter-store-context';
import SecureStorage from '../../services/secureStorage';

export enum FilterLevel {
  LEVEL_ONE, LEVEL_TWO, LEVEL_THREE
}

export enum FilterLevelOne {
  AE,
  FINANCED,
  LAND_USE_AND_REMOVALS,
  PHYSICAL_RISK
}

export enum FilterLevelTwo {
  AE_EMISSIONS, AE_PHYSICAL,
  FINANCED_EMISSIONS, FINANCED_PHYSICAL,
  LAND_USE_LAND_USE, LAND_USE_LAND_REMOVALS,
  PHYSICAL_RISK_ACUTE, PHYSICAL_RISK_CHRONIC,
}

export enum FilterLevelThree {
  // AE
  AE_EMISSION_EMISSION_SUMMARY, AE_EMISSION_EMISSION_GHG_TYPES, AE_EMISSION_SECTOR, AE_EMISSION_REGION,
  AE_EMISSION_INTENSITY_PER_HA_PER_FARM, AE_EMISSION_INTENSITY_NON_BIO_EMISSION, AE_EMISSION_INTENSITY_INTENSITY_HA, AE_EMISSION_INTENSITY_INTENSITY_PRODUCTION,

  // FINANCED
  FINANCED_EMISSION_SCOPE, FINANCED_EMISSION_SECTOR, FINANCED_EMISSION_REGION, FINANCED_EMISSION_OWNER, FINANCED_EMISSION_ASSET,
  FINANCED_PHYSICAL_EEI, FINANCED_PHYSICAL_PEI, FINANCED_PHYSICAL_WACI,

  // LAND USE AND REMOVALS
  LAND_USE_SUMMARY, LAND_USE_MAP, LAND_USE_LAND_USE_TYPE,
  LAND_USE_REMOVALS_SUMMARY, LAND_USE_REMOVALS_MAP, LAND_USE_REMOVALS_CARBON_STOCK_TYPE,

  // PHYSICAL RISK
  PHYSICAL_RISK_ACUTE_DROUGHT, PHYSICAL_RISK_ACUTE_FLOOD,
  PHYSICAL_RISK_CHRONIC_TEMPERATURE, PHYSICAL_RISK_CHRONIC_RAINFALL,
}

const FilterSection = ({ callback }: { callback?: ({ levelOneId, levelTwoId, levelThreeId }: { levelOneId: number, levelTwoId: number, levelThreeId: number }) => any; }) => {
  const filterStore = useContext(FilterStoreContext);
  const userInfo = SecureStorage.getInstance().getItem('userInfo');
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterLevelOneId, setFilterLevelOneId] = useState<number>(parseInt(searchParams.get("lv1") || "0"));
  const [filterLevelTwoId, setFilterLevelTwoId] = useState<number>(parseInt(searchParams.get("lv2") || "0"));
  const [filterLevelThreeId, setFilterLevelThreeId] = useState<number>(parseInt(searchParams.get("lv3") || "0"));

  const [levelTwoFilters, setLevelTwoFilters] = useState<any[]>([]);
  const [levelThreeFilters, setLevelThreeFilters] = useState<any[]>([]);

  let FILTER_DATA = [
    {
      id: FilterLevelOne.AE,
      name: "Absolute Emissions",
      children: [
        {
          id: FilterLevelTwo.AE_EMISSIONS, name: "Emission Summary", children: [
            { id: FilterLevelThree.AE_EMISSION_EMISSION_SUMMARY, name: "Emission summary" },
            { id: FilterLevelThree.AE_EMISSION_EMISSION_GHG_TYPES, name: "GHG Types & Sources" },
            { id: FilterLevelThree.AE_EMISSION_SECTOR, name: "Sector" },
            { id: FilterLevelThree.AE_EMISSION_REGION, name: "Region" },
          ]
        },
        {
          id: FilterLevelTwo.AE_PHYSICAL, name: "Emission Intensity", children: [
            // { id: FilterLevelThree.AE_EMISSION_INTENSITY_PER_HA_PER_FARM, name: "Emissions intensity per hectare by farm" },
            // { id: FilterLevelThree.AE_EMISSION_INTENSITY_NON_BIO_EMISSION, name: "Non-biological emissions intensity by scope" },
            { id: FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA, name: "Intensity / ha" },
            { id: FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_PRODUCTION, name: "Intensity / production" },
          ]
        },
      ],
    },
    {
      id: FilterLevelOne.FINANCED,
      name: "Financed Emissions",
      children: [
        {
          id: FilterLevelTwo.FINANCED_EMISSIONS, name: "Emissions", children: [
            { id: FilterLevelThree.FINANCED_EMISSION_SCOPE, name: "Scope" },
            { id: FilterLevelThree.FINANCED_EMISSION_SECTOR, name: "Sector" },
            { id: FilterLevelThree.FINANCED_EMISSION_REGION, name: "Region" },
          ]
        },
        {
          id: FilterLevelTwo.FINANCED_PHYSICAL, name: "Physical Emissions Intensity", children: [
            { id: FilterLevelThree.FINANCED_PHYSICAL_EEI, name: "EEI" },
            { id: FilterLevelThree.FINANCED_PHYSICAL_PEI, name: "PEI" },
            { id: FilterLevelThree.FINANCED_PHYSICAL_WACI, name: "WACI" },
          ]
        },
      ],
    },
    {
      id: FilterLevelOne.LAND_USE_AND_REMOVALS,
      name: "Land Use & Carbon Stock",
      children: [
        {
          id: FilterLevelTwo.LAND_USE_LAND_USE, name: "Land Use", children: [
            { id: FilterLevelThree.LAND_USE_SUMMARY, name: "Summary" },
            { id: FilterLevelThree.LAND_USE_LAND_USE_TYPE, name: "Land Use Type" },
            { id: FilterLevelThree.LAND_USE_MAP, name: "Map" },
          ]
        },
        {
          id: FilterLevelTwo.LAND_USE_LAND_REMOVALS, name: "Carbon Stock", children: [
            { id: FilterLevelThree.LAND_USE_REMOVALS_SUMMARY, name: "Summary" },
            { id: FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE, name: "Carbon Stock Type" },
            { id: FilterLevelThree.LAND_USE_REMOVALS_MAP, name: "Map" },
          ]
        },
      ],
    },
    {
      id: FilterLevelOne.PHYSICAL_RISK,
      name: "Physical Risk",
      disabled: true,
      children: [
        {
          id: FilterLevelTwo.PHYSICAL_RISK_ACUTE, name: "Acute Risks", children: [
            { id: FilterLevelThree.PHYSICAL_RISK_ACUTE_DROUGHT, name: "Drought" },
            { id: FilterLevelThree.PHYSICAL_RISK_ACUTE_FLOOD, name: "Flood" },
          ]
        },
        {
          id: FilterLevelTwo.PHYSICAL_RISK_CHRONIC, name: "Chronic Risks", children: [
            { id: FilterLevelThree.PHYSICAL_RISK_CHRONIC_TEMPERATURE, name: "Temperature" },
            { id: FilterLevelThree.PHYSICAL_RISK_CHRONIC_RAINFALL, name: "Rainfall" },
          ]
        },
      ],
    }
  ];

  useEffect(() => {
    const lv1 = searchParams.get("lv1");
    const lv2 = searchParams.get("lv2");
    const lv3 = searchParams.get("lv3");

    if (lv1 && lv2 && lv3) {
      setFilterLevelOneId(+lv1);
      setFilterLevelTwoId(+lv2);
      setFilterLevelThreeId(+lv3);

      let levelTwo: any = FILTER_DATA.find((d: any) => d.id === +lv1);
      setLevelTwoFilters(levelTwo?.children || []);
      let levelThree: any = levelTwo?.children.find((d: any) => d.id === +lv2);
      setLevelThreeFilters(levelThree?.children || []);

      if (callback) callback({ levelOneId: +lv1, levelTwoId: +lv2, levelThreeId: +lv3, });
    } else {
      onChangeLevelFilter(FILTER_DATA[0].id, FilterLevel.LEVEL_ONE);
    }

    // console.log(userInfo)
  }, []);

  const onChangeLevelFilter = (filterId: number, level: FilterLevel) => {
    // if (callback) callback(filterId, level);
    let levelOneId: any;
    let levelTwoId: any;
    let levelThreeId: any;

    switch (level) {
      case FilterLevel.LEVEL_ONE:
        setFilterLevelOneId(filterId);
        let levelTwo: any = FILTER_DATA.find((d: any) => d.id === filterId);

        levelOneId = filterId;
        levelTwoId = levelTwo?.children[0].id;
        levelThreeId = levelTwo?.children[0].children[0].id;

        setFilterLevelTwoId(levelTwoId);
        setLevelTwoFilters(levelTwo?.children);

        setLevelThreeFilters(levelTwo.children[0].children);
        setFilterLevelThreeId(levelThreeId);


        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          lv1: levelOneId,
          lv2: levelTwoId,
          lv3: levelThreeId
        });
        if (callback) callback({ levelOneId: levelOneId, levelTwoId: levelTwoId, levelThreeId: levelThreeId, });
        break;
      case FilterLevel.LEVEL_TWO:
        setFilterLevelTwoId(filterId);
        let levelThree: any = levelTwoFilters.find((d: any) => d.id === filterId);
        setLevelThreeFilters(levelThree.children);
        setFilterLevelThreeId(levelThree?.children[0].id);

        levelOneId = filterLevelOneId;
        levelTwoId = filterId;
        levelThreeId = levelThree?.children[0].id;

        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          lv1: levelOneId,
          lv2: levelTwoId,
          lv3: levelThreeId
        });
        if (callback) callback({ levelOneId: levelOneId, levelTwoId: levelTwoId, levelThreeId: levelThreeId, });
        break;
      case FilterLevel.LEVEL_THREE:
        setFilterLevelThreeId(filterId);

        levelOneId = filterLevelOneId;
        levelTwoId = filterLevelTwoId;
        levelThreeId = filterId;

        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          lv1: levelOneId,
          lv2: levelTwoId,
          lv3: levelThreeId
        });
        if (callback) callback({ levelOneId: levelOneId, levelTwoId: levelTwoId, levelThreeId: levelThreeId, });
        break;
    }
  };

  if (filterStore.entityLevel !== EntityLevel.ORGANIZATION) {
    FILTER_DATA = FILTER_DATA.filter((d: any) => d.id !== FilterLevelOne.FINANCED);
    if (filterLevelOneId === FilterLevelOne.FINANCED) {
      onChangeLevelFilter(FILTER_DATA[0].id, FilterLevel.LEVEL_ONE);
    }
  }

  return (
    <Box className="mt-4 mb-2 px-1">
      <Box className="flex items-center gap-8">
        {
          FILTER_DATA.map((d: any, i: number) => (
            <Tab isActive={filterLevelOneId === d.id} onClick={() => onChangeLevelFilter(d.id, FilterLevel.LEVEL_ONE)} disabled={d.disabled}>{d.name}</Tab>
          ))
        }
      </Box>

      <Box className="grid grid-cols-2 gap-1 mt-3">
        {levelTwoFilters.map((filter: any, i: number) => (
          <button key={`filter_level_2_${i}`}
            onClick={() => onChangeLevelFilter(filter.id, FilterLevel.LEVEL_TWO)}
            className={`${filterLevelTwoId === filter.id ? "bg-sky-100 text-sky-600 font-bold" : "bg-gray-200 text-gray-600"} rounded-t-lg p-2`}>
            {filter.name}
          </button>
        ))}
      </Box>
      <Box className={`p-2 bg-sky-100 border grid ${levelThreeFilters.length > 3 ? "grid-cols-4" : levelThreeFilters.length > 2 ? "grid-cols-3" : levelThreeFilters.length > 1 ? "grid-cols-2" : "grid-cols-1"} gap-3`}>
        {levelThreeFilters.map((filter: any, i: number) => (
          <button key={`filter_level_3_${i}`}
            onClick={() => onChangeLevelFilter(filter.id, FilterLevel.LEVEL_THREE)}
            className={`bg-white border-2 p-2 rounded-lg ${filterLevelThreeId === filter.id ? " border-sky-600 text-sky-600 font-semibold" : "border-white"}`}>
            {filter.name}
          </button>
        ))}
      </Box>
    </Box>
  );
};

const Tab = ({ children, isActive, onClick, disabled }: { children?: any, isActive: boolean, onClick: () => any, disabled?: boolean; }) => {
  return (<>
    <Box>
      <button className={`text-lg ${disabled ? "text-gray-300" : isActive ? "font-semibold" : "text-gray-500"} transition-all hover:opacity-75 ${disabled ? "cursor-not-allowed" : ""}`} onClick={disabled ? () => { } : onClick}>{children}</button>
      <span className={`block w-12 ${isActive ? "bg-sky-600" : "opacity-0"} rounded-lg mt-1`} style={{ height: 5 }} />
    </Box>
    <Divider orientation='vertical' flexItem sx={{ mb: 0.5, opacity: 0.8 }} />
  </>);
};


export default FilterSection;
