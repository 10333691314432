import React, { useContext, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import ReactECharts from "echarts-for-react";

// import echarts
import * as echarts from 'echarts';
import { FilterContext } from '../../context/filter-bar-context';
import BannerText from '../BannerText';

const SimpleBarChart = observer(({ series, height = "600px", categories = [], yAxisTitle = "", benchmarkValue, targetValue, averageValue, showZoom = false, colors, loading = false, tooltipFormatter }: { series: any[], height?: string, categories: string[], yAxisTitle?: string, benchmarkValue?: number | null, targetValue?: number | null, averageValue?: number | null, showZoom?: boolean, colors?: string[], loading?: boolean, tooltipFormatter?: (value: any) => string }) => {
  const { showBenchmark, showTarget } = useContext(FilterContext);

  const markLineData: any[] = [];

  if (benchmarkValue && showBenchmark) {
    markLineData.push({
      yAxis: benchmarkValue,
      name: 'Benchmark',
      lineStyle: {
        color: '#FFE3A3',
        type: 'solid',
        width: 2
      },
      label: {
        show: true,
        formatter: () => `Benchmark:\n${(+benchmarkValue).toFixed(2)} ${yAxisTitle}`
      }
    })
  }

  if (targetValue && showTarget) {
    markLineData.push({
      yAxis: targetValue,
      name: 'Target',
      lineStyle: {
        type: 'solid',
        color: '#A3F6FF',
        width: 2
      },
      label: {
        show: true,
        formatter: () => `Target:\n${(+targetValue).toFixed(2)} ${yAxisTitle}`
      }
    })
  }

  if (averageValue) {
    markLineData.push({
      yAxis: averageValue,
      name: 'Average',
      lineStyle: {
        type: 'solid',
        color: '#1d62f4',
        width: 2
      },
      label: {
        show: true,
        formatter: () => `Average:\n${(+averageValue).toFixed(2)} ${yAxisTitle}`,
        // offset: averageValue > 0 ? [0, 20] : [0, -20]
      }
    })
  }

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormatter
    },
    grid: {
      top: 80,
      left: 70,
      right: 100,
      bottom: 120,
    },
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          show: showZoom,
          yAxisIndex: 'none'
        },
        dataView: { readOnly: true },
        // magicType: { type: ['line', 'bar'] },
        // restore: {},
        saveAsImage: {}
      }
    },
    color: colors,
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: categories
    },
    yAxis: {
      type: 'value',
      name: yAxisTitle,
      axisLabel: {
        // formatter: (value: number) => `${value} ${yAxisTitle}`
      }
    },
    dataZoom: showZoom ? [
      {
        type: 'inside',
        xAxisIndex: [0, 1],
        start: 0,
        end: 100
      },
      {
        show: true,
        xAxisIndex: [0, 1],
        type: 'slider',
        bottom: 50,
        start: 0,
        end: 100
      }
    ] : [],
    legend: {
      show: true,
      bottom: 0
    },
    series: series.map((item, seriesIndex) => ({
      ...item,
      markLine: {
        data: (seriesIndex === 0) ? markLineData : []
      },
      // labelLayout: {moveOverlap: 'shiftY'},
      // label: {
      //   // show: true,
      //   // formatter: (value: any) => {
      //   //   console.log(value.value)
      //   //   return `${value.value ? (+value.value).toFixed(2) : 0} ${yAxisTitle}`
      //   // }
      // }
    }))
  };

  return (
    <div id="chart" style={{ width: "100%", height: `${height}` }}>
      {
        loading
          ? <BannerText text={"Loading..."} />
          : categories.length === 0 || series.length === 0
            ? <BannerText text={"No Data Found"} subText={"Please try different filters or choose a different year"} />
            : <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
      }
    </div>
  );
})

export default SimpleBarChart
