import { Checkbox, FormControlLabel } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import theme from '../styles/theme'

const VmCheckBox = ({ sx, label, checked, disabled, onChange }: { sx?: any, label: string, checked: boolean, disabled: boolean, onChange: (checked: boolean) => void }) => {
  return (
    <FormControlLabel
      label={label}
      sx={{ mr: 0 }}
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(event: any) => onChange && onChange(event.target.checked)}
          sx={{ color: "gray", paddingY: 0, '&.Mui-checked': { color: theme.palette.primary.main }, ...sx }}
        />}
    />
  )
}

export default observer(VmCheckBox)
