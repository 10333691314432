import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SecureStorage from '../services/secureStorage'; // Secure storage for token handling
import { signInWithEmailPassword } from '../api/accountService'; // Account service for login API
import { GetLocalStorage, SetLocalStorage } from '../utils/cryptography';
import { ClimateScenario, EntityLevel, OrganizationTypes } from '../utils/options';
import { getEntityList, getLatestAvailableFY, getYearOptions, searchEntityList } from '../api/explorerService';
import moment from 'moment';
import { FilterStoreContext } from '../context/filter-store-context';

interface AuthContextType {
    isAuthenticated: boolean;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

// Custom hook for authentication
export const useAuth = (): AuthContextType => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const navigate = useNavigate();

    // Check authentication on component mount
    useEffect(() => {
        const checkAuth = () => {
            const persistedToken = GetLocalStorage('accessToken');
            const token = SecureStorage.getInstance().getJwtToken() || persistedToken; // Get JWT token from secure storage

            if (token) {
                setIsAuthenticated(true); // If token exists, user is authenticated
            } else {
                setIsAuthenticated(false);
            }
        };

        checkAuth(); // Run on mount
    }, []);

    // Login function
    const login = async (email: string, password: string): Promise<void> => {
        try {
            const response = await signInWithEmailPassword(email, password); // Call login API
            if (response.data && response.data.idToken) {
                let userInfo = response.data;

                SecureStorage.getInstance().setJwtToken(userInfo.idToken); // Store token securely in memory
                SetLocalStorage('accessToken', userInfo.idToken);

                const latestAvailableFYResponse = await getLatestAvailableFY({
                    sector: "",
                    regions: "",
                    force_refresh: false,
                    include_trajectory: false,
                    climateScenario: ClimateScenario.CURRENT
                })

                const latestAvailableFY = latestAvailableFYResponse.data;
                SecureStorage.getInstance().setItem('latestAvailableFY', latestAvailableFY)
                SetLocalStorage('latestAvailableFY', latestAvailableFY)

                // FETCH ASSET OWNER ID
                if (userInfo.organization_type === OrganizationTypes.ASSET_OWNER) {
                    const entityList = await getEntityList({
                        force_refresh: false,
                        include_trajectory: false,
                        climateScenario: ClimateScenario.CURRENT,
                        asset_owner_id: "",
                        asset_id: "",
                        emission_system_id: "",
                        financial_year: "" + latestAvailableFY
                    }, "organization")

                    userInfo = {
                        ...userInfo,
                        assetOwnerId: entityList.data.items[0].id,
                        assetOwnerName: entityList.data.items[0].name
                    }
                }

                SecureStorage.getInstance().setItem('userInfo', userInfo);
                SetLocalStorage('userInfo', userInfo);

                SecureStorage.getInstance().setItem('organizationId', userInfo.organization_id);
                SetLocalStorage('organizationId', userInfo.organization_id);

                setIsAuthenticated(true); // Update auth state
                navigate('/summary/dashboard'); // Redirect to dashboard
            } else {
                throw new Error('Invalid login');
            }
        } catch (error) {
            console.error('Login error:', error);
            throw error; // Let the component handle errors (e.g., show a message to the user)
        }
    };

    // Logout function
    const logout = (): void => {
        SecureStorage.getInstance().clearJwtToken(); // Clear the token from memory
        setIsAuthenticated(false); // Update auth state
        navigate('/login'); // Redirect to login page
    };

    return { isAuthenticated, login, logout };
};
