import React from 'react'
import { observer } from 'mobx-react-lite'
import ChangesLabel from './changes-label'
import { Box, Button } from '@mui/material'
import { GetRiskLevelBGColor, GetRiskLevelTextColor } from '../utils/colour'
import { PRRiskLevel } from '../utils/options'

const PRGradientStatsCircle = observer(({
  title, value, changeAmount, className, riskLevel, onClickViewTableButton
}: {
  title: string, value: number | string, changeAmount: number, className?: string, riskLevel: PRRiskLevel, onClickViewTableButton?: () => void
}) => {
  return (
    <Box className={`flex flex-col aspect-square items-center px-4 justify-center gap-1 ${className} rounded-full`}
      sx={{ background: `linear-gradient(0deg, ${GetRiskLevelBGColor(riskLevel)} 0%, rgba(255,255,255,1) 100%)` }}
    >
      <Box
        className='flex items-center justify-center gap-2 rounded-md px-4 py-1 font-bold mb-4'
        sx={{
          background: GetRiskLevelBGColor(riskLevel),
          color: GetRiskLevelTextColor(riskLevel)
        }}
      >
        <p className=''>{riskLevel}</p>
      </Box>
      <p>{title}</p>
      <p className="text-[2.5rem] font-bold">{value ? (+value).toLocaleString() : '--'}</p>
      <ChangesLabel changeAmount={changeAmount} />
      {
        onClickViewTableButton && <Button variant='text' onClick={onClickViewTableButton}>View Scale</Button>
      }
    </Box>
  )
})

export default PRGradientStatsCircle

