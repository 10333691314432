import { PRRiskLevel } from "./options";
export const hexToRgb = (hex: string): { r: number, g: number, b: number } => {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 };
}

export const rgbToHex = (r: number, g: number, b: number): string => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export const lerp = (start: number, end: number, percentage: number): number => {
    return start + (end - start) * percentage;
}

export const lerpColor = (color1: string, color2: string, percentage: number): string => {
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    const r = Math.round(lerp(rgb1.r, rgb2.r, percentage));
    const g = Math.round(lerp(rgb1.g, rgb2.g, percentage));
    const b = Math.round(lerp(rgb1.b, rgb2.b, percentage));

    return rgbToHex(r, g, b);
}

export const RandomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

export const RandomRGB = () => `rgb(${RandomNum()}, ${RandomNum()}, ${RandomNum()})`;

export const GetRiskLevelTextColor = (riskLevel: PRRiskLevel) => {
    switch (riskLevel) {
        case PRRiskLevel.LOW:
            return "#00CC93"
        case PRRiskLevel.MEDIUM:
            return "#FCA724"
        case PRRiskLevel.HIGH:
            return "#FF131A"
        default:
            return "#f7f9fd"
    }
}

export const GetRiskLevelBGColor = (riskLevel: PRRiskLevel) => {
    switch (riskLevel) {
        case PRRiskLevel.LOW:
            return "#B4FEEA"
        case PRRiskLevel.MEDIUM:
            return "#FFEED4"
        case PRRiskLevel.HIGH:
            return "#FFD7D9"
        default:
            return "#f7f9fd"
    }
}

export const defaultDataKeyColours = {
    total: "#1b1753",
    GrossEmission: "#15f5ba",
    ProductionIntensity: "#002b8d",
    above_ground_carbon: "#0061ff",
    below_ground_biomass: "#c8c369",
    soil_carbon_stock: "#018c68",
}