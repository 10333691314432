import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import ChangesLabel from './changes-label'
import { Box } from '@mui/material'
import { GetRiskLevelBGColor, GetRiskLevelTextColor } from '../utils/colour'
import { HtmlTooltip } from '../components/htmlToolTip'
import { FilterContext } from '../context/filter-bar-context'
import { PRTableLineType } from '../utils/options'

const PrDecileTable = observer(({ data, isHorizontal = false, lineInfo }: { data: any[], isHorizontal?: boolean, lineInfo?: { average: number, benchmark: number, target: number } }) => {
  const { showBenchmark, showTarget } = useContext(FilterContext)

  const tableCellStyle = "px-4 py-2"

  const getLineTypeColor = (lineType: PRTableLineType) => {
    switch (lineType) {
      case PRTableLineType.AVERAGE: return "#b2d2ff"
      case PRTableLineType.BENCHMARK: return "#ffe3a3"
      case PRTableLineType.TARGET: return "#a3f6ff"
    }
  }

  const getShortenedAsset = (asset: any[]) => {
    let returnStr = ""
    if (asset.length > 2) {
      returnStr = asset.slice(0, 2).map((item: any) => item.name).join(", ") + ` and ${asset.length - 2} more`
    } else {
      returnStr = asset.map((item: any) => item.name).join(", ")
    }
    return returnStr
  }

  return (
    <table width={"100%"} className='rounded-lg overflow-hidden'>
      {
        isHorizontal
          ? <>
            {/* HORIZONTAL - SUMMARY TABLE */}
            <thead className={`z-10 text-white rounded-lg bg-themeDarkBlue whitespace-nowrap`}>
              <td className={`${tableCellStyle} w-24 opacity-55 h-10`}>Score</td>
              {
                data.map((item: any, index: number) => {
                  return (
                    <>
                      {
                        item.Deciles && item.Deciles.length > 0 && item.Deciles.map((decile: any, decileIndex: number) => {
                          const hasAvg = lineInfo ? lineInfo.average >= decile.rangeStart && lineInfo.average < decile.rangeEnd : false
                          const hasBenchmark = lineInfo ? lineInfo.benchmark >= decile.rangeStart && lineInfo.benchmark < decile.rangeEnd : false
                          const hasTarget = lineInfo ? lineInfo.target >= decile.rangeStart && lineInfo.target < decile.rangeEnd : false

                          return <td className={`${tableCellStyle} text-white bg-themeDarkBlue relative`}>
                            <p className='z-10 relative text-center'>{decile.rangeStart}-{decile.rangeEnd}</p>
                            {/* LINE */}
                            {
                              ((hasTarget) || (hasBenchmark) || hasAvg) && <>
                                <Box
                                  className="absolute top-10 -bottom-36 left-1/2 -translate-x-1/2 w-[2px] pointer-events-none z-10"
                                  style={{
                                    background: getLineTypeColor(hasTarget ? PRTableLineType.TARGET : hasBenchmark ? PRTableLineType.BENCHMARK : PRTableLineType.AVERAGE)
                                  }}></Box>
                              </>
                            }
                            <Box className='absolute top-10 flex items-center justify-start left-1/2 -translate-x-1/2 rounded-b-md overflow-hidden z-20'>
                              {/* TEXT */}
                              {
                                hasAvg && <Box className='px-1 py-0.5 pr-2 z-10' style={{ background: getLineTypeColor(PRTableLineType.AVERAGE) }}>
                                  <p className='text-black text-sm'>{(!hasBenchmark && !hasTarget) ? "Average" : "Avg"}</p>
                                </Box>
                              }
                              {
                                (hasBenchmark) && <Box className='px-1 py-0.5 pr-2 z-10' style={{ background: getLineTypeColor(PRTableLineType.BENCHMARK) }}>
                                  <p className='text-black text-sm'>{(!hasAvg && !hasTarget) ? "Benchmark" : "Benc"}</p>
                                </Box>
                              }
                              {
                                (hasTarget) && <Box className='px-1 py-0.5 pr-2 z-10' style={{ background: getLineTypeColor(PRTableLineType.TARGET) }}>
                                  <p className='text-black text-sm'>{(!hasAvg && !hasBenchmark) ? "Target" : "Tar"}</p>
                                </Box>
                              }
                            </Box>
                          </td>
                        })
                      }
                    </>
                  )
                })
              }
            </thead>

            <tbody>
              {/* CUSTOMERS */}
              <tr>
                <td className={`${tableCellStyle} text-white bg-themeDarkBlue h-24`}>Customers (#)</td>
                {
                  data.map((item: any, index: number) => {
                    // console.log(item)
                    return <>
                      {
                        item.Deciles && item.Deciles.length > 0 && item.Deciles.map((decile: any, decileIndex: number) => {
                          return <td
                            key={`customers_count_${decileIndex}_${index}`}
                            className={`${tableCellStyle} text-center`}
                            style={{ background: `linear-gradient(0deg, ${GetRiskLevelBGColor(item.RiskLevel)} 0%, rgba(255,255,255,1) 100%)` }}
                          >
                            <p className='z-10 relative font-bold text-[1.2rem]'>{decile.no_customers || decile.customers}</p>
                          </td>
                        })
                      }
                    </>
                  })
                }
              </tr>

              {/* RISK LEVEL ROW */}
              <tr>
                <td className={`${tableCellStyle} text-white bg-themeDarkBlue h-12`}>Risk level</td>
                {
                  data.map((item: any, index: number) => {
                    return <>
                      {
                        item.Deciles && item.Deciles.length > 0 && item.Deciles.map((decile: any, decileIndex: number) => {
                          return <td
                            key={`customers_risk_level_${decileIndex}_${index}`}
                            style={{ background: GetRiskLevelBGColor(item.RiskLevel) }}
                            className={`${tableCellStyle} relative`}
                          >
                            {
                              item.Deciles.length > 0 && decileIndex === Math.floor(item.Deciles.length / 2) && <>
                                <p className={`z-10 absolute left-0 bottom-2 text-[1.8rem] font-bold opacity-35 ${item.Deciles.length % 2 === 0 && "-translate-x-1/2"}`}>{item.RiskLevel}</p>
                              </>
                            }
                          </td>
                        })
                      }
                    </>
                  })
                }
              </tr>
            </tbody>

          </>
          : <>
            {/* VERTICAL - DETAILED TABLE */}
            <thead className={`z-10 text-white rounded-lg bg-themeDarkBlue`}>
              <td className={`${tableCellStyle} w-24`}>%</td>
              <td className={`${tableCellStyle} min-w-20`}></td>
              <td className={`${tableCellStyle}`}>Asset(#)</td>
              <td className={`${tableCellStyle}`}>Assets</td>
              <td className={`${tableCellStyle} min-w-44`}></td>
            </thead>
            {
              data.map((item: any, index: number) => {
                return (
                  <>
                    {
                      item.Deciles && item.Deciles.length > 0 && item.Deciles.map((decile: any, decileIndex: number) => {
                        const hasAvg = lineInfo ? lineInfo.average >= decile.rangeStart && lineInfo.average < decile.rangeEnd : false
                        const hasBenchmark = lineInfo ? lineInfo.benchmark >= decile.rangeStart && lineInfo.benchmark < decile.rangeEnd : false
                        const hasTarget = lineInfo ? lineInfo.target >= decile.rangeStart && lineInfo.target < decile.rangeEnd : false

                        return <>
                          <tr key={decileIndex} className='relative'
                            style={{ background: `linear-gradient(270deg, ${GetRiskLevelBGColor(item.RiskLevel)} 42%, rgba(255,255,255,1) 100%)` }}
                          >
                            <td className={`${tableCellStyle} text-white bg-themeDarkBlue`}>
                              <p className='z-10 relative text-center whitespace-nowrap'>{decile.rangeStart}-{decile.rangeEnd}</p>
                            </td>
                            <td className={`relative`}>
                            </td>
                            <td className={`${tableCellStyle} text-center`}>
                              <p className='z-10 relative font-bold text-[1.2rem]'>{decile.no_customers || decile.customers}</p>
                            </td>
                            <td className={`${tableCellStyle} cursor-pointer`} >
                              <HtmlTooltip title={<>
                                <ul>
                                  {
                                    decile.children.map((child: any) => {
                                      return <li>{child.name}</li>
                                    })
                                  }
                                </ul>
                              </>}>
                                <p className='z-10 relative'>{getShortenedAsset(decile.children)}</p>
                              </HtmlTooltip>
                            </td>
                            <td className={`${tableCellStyle} relative font-bold text-[2.4rem]`}>
                              {
                                item.Deciles.length > 0 && decileIndex === Math.ceil(item.Deciles.length / 2) && <>
                                  <p className={`text-[#00000030] absolute right-4 top-0 ${item.Deciles.length % 2 === 1 ? "-translate-y-[calc(50%+1rem)]" : "-translate-y-1/2 "}`}>{item.RiskLevel}</p>
                                </>
                              }
                            </td>
                            {/* LINE */}
                            {
                              ((hasTarget && showTarget) || (hasBenchmark && showBenchmark) || hasAvg) && <>
                                <Box className="absolute left-24 right-0 top-1/2 -translate-y-1/2 h-[2px] pointer-events-none" style={{ background: getLineTypeColor(hasTarget ? PRTableLineType.TARGET : hasBenchmark ? PRTableLineType.BENCHMARK : PRTableLineType.AVERAGE) }}></Box>
                              </>
                            }
                            <Box className='absolute left-24 flex items-center justify-start top-1/2 -translate-y-1/2 z-0 rounded-r-md overflow-hidden'>
                              {/* TEXT */}
                              {
                                hasAvg && <Box className='px-1 py-0.5 pr-2 z-10' style={{ background: getLineTypeColor(PRTableLineType.AVERAGE) }}>
                                  <p className='text-black text-sm'>{(!hasBenchmark && !hasTarget) ? "Average" : "Avg"}</p>
                                </Box>
                              }
                              {
                                (hasBenchmark && showBenchmark) && <Box className='px-1 py-0.5 pr-2 z-10' style={{ background: getLineTypeColor(PRTableLineType.BENCHMARK) }}>
                                  <p className='text-black text-sm'>{(!hasAvg && !hasTarget) ? "Benchmark" : "Benc"}</p>
                                </Box>
                              }
                              {
                                (hasTarget && showTarget) && <Box className='px-1 py-0.5 pr-2 z-10' style={{ background: getLineTypeColor(PRTableLineType.TARGET) }}>
                                  <p className='text-black text-sm'>{(!hasAvg && !hasBenchmark) ? "Target" : "Tar"}</p>
                                </Box>
                              }
                            </Box>
                          </tr >
                        </>
                      })
                    }
                  </>
                )
              })
            }
          </>
      }

    </table >
  )
})

export default PrDecileTable

