import React from "react";
import { filterStore } from "../stores/filterStore";

export const FilterStoreContext = React.createContext(filterStore);

export const FilterStoreProvider = ({ children }: { children: React.ReactNode }) => (
  <FilterStoreContext.Provider value={filterStore}>
    {children}
  </FilterStoreContext.Provider>
);
