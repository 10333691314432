export enum ClimateScenario {
  CURRENT = "ssp245",
  DISORDERLY = "ssp126",
}

export const ClimateScenarioOptions = [
  {
    id: ClimateScenario.DISORDERLY,
    name: "1.5 (RCP2.6 /NGFS Disorderly-Delayed)"
  },
  {
    id: ClimateScenario.CURRENT,
    name: "2.5 (RCP4.5/NGFS Hothouse-Current)"
  },
]

export enum SearchType {
  REGION = "region",
  SECTOR = "sector",
}

export enum PRRiskLevel {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High"
}

export enum EntityLevel {
  ORGANIZATION = "organization",
  ASSET_OWNER = "asset_owner",
  ASSET = "asset"
}

export interface EntityObject {
  id: string
  name: string
}

export enum PRTableLineType {
  AVERAGE = "Average",
  BENCHMARK = "Benchmark",
  TARGET = "Target"
}

export enum OrganizationTypes {
  ORGANIZATION = "enterprise",
  ASSET_OWNER = "pro",
  // ORGANIZATION = "pro",
  // ASSET_OWNER = "enterprise",
  ASSET = "asset"
}