import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated to use 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'; // Import ThemeProvider from MUI
import CssBaseline from '@mui/material/CssBaseline'; // For resetting browser styles
import App from './App'; // Your main App component
import theme from './styles/theme'; // Import your custom theme
import { FilterContextProvider } from './context/filter-bar-context';
import { FilterStoreProvider } from './context/filter-store-context';
import { SnackbarProvider } from 'notistack';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement); // Updated to use createRoot
  root.render(
    <React.StrictMode>
      <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <FilterStoreProvider>
          <FilterContextProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline /> {/* Add this to normalize styles */}
              <App />
            </ThemeProvider>
          </FilterContextProvider>
        </FilterStoreProvider>
      </SnackbarProvider>
    </React.StrictMode>
  );
}
