import React from 'react'
import { observer } from 'mobx-react-lite'
import { ClimateScenario, ClimateScenarioOptions } from '../utils/options'
import { Box, Button, Checkbox, FormControl, FormControlLabel } from '@mui/material'

const ClimateChangeToggleBar = observer(({ selectedClimateScenario, setSelectedClimateScenario, showTotalOnly, setShowTotalOnly }:
  { selectedClimateScenario: ClimateScenario, setSelectedClimateScenario: (climateScenario: ClimateScenario) => void, showTotalOnly?: boolean, setShowTotalOnly?: (isChecked: boolean) => void }) => {
  const isSelected = (climateScenario: ClimateScenario) => selectedClimateScenario === climateScenario
  return (
    <>
      <Box className="flex justify-between ">
        <Box className="flex items-center gap-2">
          {
            ClimateScenarioOptions.map((option) => (
              <Button
                key={option.id}
                variant={"outlined"}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: isSelected(option.id) ? "#0958d920" : "unset",
                  filter: isSelected(option.id) ? "brightness(1.2)" : "saturate(0.2)",
                  opacity: isSelected(option.id) ? 1 : 0.7,
                  transition: "all 0.3s ease-in-out"
                }}
                onClick={() => setSelectedClimateScenario(option.id)}>{option.name}</Button>
            ))
          }
        </Box >
        <Box>
          {
            setShowTotalOnly && <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <FormControlLabel
                control={<Checkbox checked={showTotalOnly} onChange={(e) => setShowTotalOnly ? setShowTotalOnly(e.target.checked) : null} />}
                label="Show Detail"
              />
            </FormControl>
          }
        </Box>
      </Box >
    </>
  )
})

export default ClimateChangeToggleBar
