import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import SecureStorage from '../services/secureStorage';
import AuthStatus from './AuthStatus';
import { GetLocalStorage, SetLocalStorage } from '../utils/cryptography';

const AuthGuard = ({ children }: { children: JSX.Element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<typeof AuthStatus[keyof typeof AuthStatus]>(AuthStatus.CHECKING);
    const secureStorage = SecureStorage.getInstance();

    useEffect(() => {
        const organizationId = secureStorage.getItem('organizationId');
        const localOrganizationId = GetLocalStorage('organizationId');

        if (!organizationId && localOrganizationId) {
            secureStorage.setItem('organizationId', localOrganizationId);
        }

        const userInfo = secureStorage.getItem('userInfo');
        const localUserInfo = GetLocalStorage('userInfo');

        if (!userInfo && localUserInfo) {
            secureStorage.setItem('userInfo', localUserInfo);
        }

        const verifyToken = async () => {
            const persistedToken = GetLocalStorage('accessToken');
            const token = secureStorage.getJwtToken() || persistedToken;

            if (!secureStorage.getJwtToken()) {
                secureStorage.setJwtToken(persistedToken);
            }

            if (!token) {
                setIsAuthenticated(AuthStatus.FAIL);
                return;
            }

            const url = `${process.env.REACT_APP_API_BASEURL}/account/verify_token`;

            try {
                const response = await axios.post(url, { access_token: token });
                const authenticated = response.data.status === AuthStatus.SUCCESS
                    ? AuthStatus.SUCCESS
                    : AuthStatus.FAIL;

                setIsAuthenticated(authenticated);
            } catch (error) {
                console.error('Error verifying token:', error);
                secureStorage.clearJwtToken();
                setIsAuthenticated(AuthStatus.FAIL);
            }
        };

        verifyToken();
    }, [secureStorage]);

    if (isAuthenticated === AuthStatus.CHECKING) {
        return <div>Loading...</div>;
    }

    return isAuthenticated === AuthStatus.SUCCESS
        ? children
        : <Navigate to="/login" />;
};

export default AuthGuard;
