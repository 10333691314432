import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

const ChangesLabel = ({ changeAmount }: { changeAmount: number }) => {
  const [isPositive, setIsPositive] = useState(false);
  const [changeAmountText, setChangeAmountText] = useState(0);

  useEffect(() => {
    setIsPositive(changeAmount > 0);
    setChangeAmountText(+Math.abs(changeAmount).toFixed(0));
  }, [changeAmount]);

  return (
    <Box className="flex items-center justify-center gap-1 p-2 py-1 rounded-sm w-fit bg-[#ebf1fd]">
      {!changeAmountText
        ? <>
          <p>0%</p>
        </>
        : <>
          <p>{changeAmountText.toLocaleString()}%</p>
          {
            isPositive
              ? <ArrowUpward sx={{ fontSize: 20 }} color='error' />
              : <ArrowDownward sx={{ fontSize: 20 }} color='success' />
          }
        </>
      }
    </Box>
  )
}

export default ChangesLabel
